import React, { useState, useEffect } from 'react'
import { useRoutes, useNavigate, useLocation } from 'react-router-dom'
import { queryAllUnreadCount } from '@/api/messageCenter'
import { useSelector, useDispatch } from 'react-redux'
import eventStation from '@/utils/eventStation'
import routes from '@/routes'
import { userInfoSimple, queryMenuList, closeFirstOpen } from '@/api/common'
import saveUserAction from '@/redux/actions/userInfo'
import menuListAction from '@/redux/actions/menuList'
import saveMessageCount from '@/redux/actions/messageCount'
import { ConfigProvider, Spin } from '@arco-design/web-react';
import enUS from '@arco-design/web-react/es/locale/en-US';
import zhCN from '@arco-design/web-react/es/locale/zh-CN';
import Header from './components/Header'
import Footer from './components/Footer'
import PublicEntrance from './components/PublicEntrance'
import FirstOpen from '@/assets/common/firstOpen.svg'
import FirstClose from '@/assets/common/firstClose.svg'
import cs from 'classnames'
export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  eventStation.dispatch = dispatch;
  eventStation.location = location;
  //获取显示区域的宽度
  const contentWidth = useSelector(state => state.contentWidth)
  //存储路由的跳转方法
  const navigate = useNavigate()
  eventStation.navigate = navigate
  //设置arco组件库的语言类型
  const language = useSelector(state => state.language)
  eventStation.lang = language;
  //获取背景色
  const bodyBack = useSelector(state => state.bodyBack)
  //是否是登录页
  const isLogin = useSelector(state => state.isLogin)
  //是否是资讯详情页
  const isSecurityInfo = useSelector(state => state.isSecurityInfo)
  //是否是ioc页面
  const isIoc = useSelector(state => state.isIoc)
  //是否显示加载标识
  const loading = useSelector(state => state.loading)
  //个人信息
  const userInfo = useSelector(state => state.userInfo)
  //导航栏数据
  const menuList = useSelector(state => state.menuList)
  //顶部菜单栏
  // const [menuDataList, setMenuDataList] = useState([]);
  //生成路由表
  const [element, setElement] = useState(routes());
  //是否全景图
  const isPanoramicview = location.pathname.indexOf('treat-Img') > -1 ? true : false
  //是否是威胁知识库
  const isKnowledge = location.pathname.indexOf('knowledge') > -1 ? true : false
  //是否是API
  const isApi = location.pathname.indexOf('/api') > -1 ? true : false
  //内容去最小高度
  const [contentMinHeight, setContentMinHeight] = useState(innerHeight)
  //是否是第一次登录
  const [isFirstOpen, setIsFirstOpen] = useState(false)
  useEffect(() => {
    handleResizeMinHeight();
  }, [isIoc])
  //存储更新未读消息数的方法
  eventStation.messageCount = async () => {
    if (userInfo.loginStatus == 1 && !isLogin) {
      const { data } = await queryAllUnreadCount();
      if (data && (data.unreadMsg || data.unreadMsg === 0)) {
        dispatch(saveMessageCount(Number(data.unreadMsg)))
      }
    }
  }
  // useEffect(() => {
  //   if (userInfo.loginStatus == 1) {
  //     eventStation.messageCount();
  //   }
  // }, [userInfo])
  // useLayoutEffect(() => {
  //   console.log(element);
  // }, [])


  useEffect(() => {
    eventStation.updateMenuList();
    queryUserInfo();
    
    const messageUpdata = setInterval(() => {
      eventStation.messageCount()
    }, 1000 * 60 * 5);
    window.addEventListener('resize', handleResizeMinHeight)
    return () => {
      window.removeEventListener('resize', handleResizeMinHeight)
      clearInterval(messageUpdata)
    }
  }, [])
  //设定页面最小高度
  function handleResizeMinHeight() {
    setContentMinHeight(isIoc ? 0 : innerHeight)
  }
  //获取用户信息
  async function queryUserInfo() {
    const result = await userInfoSimple();
    // if (result.data.loginStatus == 1) {
    dispatch(saveUserAction(result.data))
    // }
  }
  //获取菜单栏
  eventStation.updateMenuList = async (callback) => {
    const result = await queryMenuList();
    const newElement = routes(result.data)
    setElement(newElement);
    // setMenuDataList(result.data)
    dispatch(menuListAction(result.data))
    if (callback) {
      callback();
    }
  }
  //获取未读消息数
  useEffect(() => {
    eventStation.userInfo = userInfo;
    eventStation.messageCount();
    setIsFirstOpen(userInfo.versionPromptStatus == 1 && !isLogin);
  }, [userInfo, isLogin])
  const ele = useRoutes(element)
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
    document.title = getTitleByPath(location.pathname);
  }, [location.pathname])

  

  // //配置页面title
  const getTitleByPath = (path) => {
    // 根据路径返回对应的标题
    switch (path) {
      case '/login':
        return language == 'zh' ? '登录-天际友盟RedQueen威胁情报中心' : 'Loggin-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/home/index':
        return language == 'zh' ? '天际友盟威胁情报中心-RedQueen' : 'TianJi Partners Threat Intelligence Center-RedQueen'
      case '/home/infoDetail':
        return language == 'zh' ? '情报详情-天际友盟RedQueen威胁情报中心' : 'TI Details-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/home/advanced':
        return language == 'zh' ? '高级情报-天际友盟RedQueen威胁情报中心' : 'PRTI-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/home/security':
        return language == 'zh' ? '安全资讯-天际友盟RedQueen威胁情报中心' : 'Security Information-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/subscription/market/card':
        return language == 'zh' ? '情报市场-天际友盟RedQueen威胁情报中心' : 'TI Market-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/subscription/info-sub/list':
        return language == 'zh' ? '情报订阅-天际友盟RedQueen威胁情报中心' : 'TI Subscription-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/subscription/info-sub/card':
        return language == 'zh' ? '创建集合-天际友盟RedQueen威胁情报中心' : 'Create Set-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/subscription/info-sub/collection':
        return language == 'zh' ? '集合详情-天际友盟RedQueen威胁情报中心' : 'Set Detail-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/loophole/default':
        return language == 'zh' ? '漏洞-天际友盟RedQueen威胁情报中心' : 'Vulnerability-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/loophole/detail':
        return language == 'zh' ? '漏洞详情-天际友盟RedQueen威胁情报中心' : 'Vulnerability Details-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/knowledge/treat-Img':
        return language == 'zh' ? '威胁全景图-天际友盟RedQueen威胁情报中心' : 'Threat Panorama-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/knowledge/treat-org':
        return language == 'zh' ? '威胁组织-天际友盟RedQueen威胁情报中心' : 'Threat actor-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/knowledge/marlware':
        return language == 'zh' ? '恶意软件-天际友盟RedQueen威胁情报中心' : 'Malware'
      case '/knowledge/attack':
        return language == 'zh' ? '攻击模式-天际友盟RedQueen威胁情报中心' : 'Attack Pattern'
      case '/knowledge/use-tools':
        return language == 'zh' ? '利用工具-天际友盟RedQueen威胁情报中心' : 'Tools'
      case '/api/mock-request':
        return language == 'zh' ? '模拟请求-天际友盟RedQueen威胁情报中心' : 'Simulating Request-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/api/call':
        return language == 'zh' ? '调用API-天际友盟RedQueen威胁情报中心' : 'Calling API-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/api/question':
        return language == 'zh' ? '常见问题-天际友盟RedQueen威胁情报中心' : 'FAQ-TianJi Partners Threat Intelligence Center-RedQueen'
      case '/center/base':
        return language == 'zh' ? '用户中心-天际友盟RedQueen威胁情报中心' : 'User Center-TianJi Partners Threat Intelligence Center-RedQueen'
      default:
        return language == 'zh' ? '天际友盟RedQueen威胁情报中心' : 'TianJi Partners Threat Intelligence Center-RedQueen'
    }
  };
  //关闭首次打开弹框
  async function closeFirstOpenDialog() {
    const result = await closeFirstOpen();
    setIsFirstOpen(false)
  }

  return (
    <ConfigProvider locale={language == 'en' ? enUS : zhCN}>
      {isLogin ? "" : <Header/>}
      {isFirstOpen ? <div className='firstOpenDialog'>
        <div className='backImage'>
          <FirstOpen />
          <FirstClose className='closeSign' onClick={closeFirstOpenDialog} />
          <div className='closeButton' onClick={closeFirstOpenDialog}></div>
        </div>
      </div> : ""}
      <div style={{ minWidth: location.pathname.indexOf('/api/')>-1?'':'1440px', position: 'relative' }}>
        {/* <div style={{ backgroundColor: bodyBack,minHeight: innerHeight - 259 }} > */}
        <div style={{ backgroundColor: bodyBack, minHeight: isSecurityInfo ? 0 : contentMinHeight }} >
          <div style={{ width: location.pathname.indexOf('/api/')>-1 && document.documentElement.clientWidth<1440?'':contentWidth, paddingTop: "60px", margin: "0 auto", paddingBottom: location.pathname.indexOf('/api/')>-1?0:52,overflow: location.pathname.indexOf('/api/')>-1?'hidden':'' }}>
            {ele}
          </div>
        </div>
        {isLogin || isPanoramicview || isApi ? "" : <Footer />}
        {isLogin || isKnowledge ? "" : <PublicEntrance />}
      </div>
      {/* //加载标识 */}
      {/* {loading ? <Spin size={12} dot className="redqueenLoading" /> : ""} */}

      {loading ? <Spin loading={true} size={23} className={cs('spinBakcColor', "redqueenLoading")} /> : ""}
      {/* <Spin delay={300} dot className="redqueenLoading" style={{display: loading ? 'flex' : 'none'}}/> */}
    </ConfigProvider>
  );
}
