/**
 * 存储页面中的中英文字典对照表
 */
const languageList = {
    //公共表
    public1: {
        zh: "时间",
        en: "Time"
    },
    public2: {
        zh: "发布时间",
        en: "Time"
    },
    public3: {
        zh: "评定时间",
        en: "Estimation Time"
    },
    public4: {
        zh: "披露时间",
        en: "Publicly"
    },
    public5: {
        zh: "更新时间",
        en: "Updated"
    },
    public6: {
        zh: "简要内容",
        en: "Overview"
    },
    public7: {
        zh: "查看更多",
        en: "More"
    },
    public8: {
        zh: "查看",
        en: "View"
    },
    public9: {
        zh: "删除",
        en: "Delete"
    },
    public10: {
        zh: "类型",
        en: "Type"
    },
    public11: {
        zh: "集合名称",
        en: "Set Name"
    },
    public12: {
        zh: "请选择",
        en: "Please Select"
    },
    public13: {
        zh: "token码",
        en: "Token"
    },
    public14: {
        zh: "人读情报",
        en: "PRTI（People-Readable Threat Intelligence）"
    },
    public15: {
        zh: "机读情报",
        en: "MRTI（Machine-Readable Threat Intelligence）"
    },
    public16: {
        zh: "情报来源",
        en: "TI Source"
    },
    public17: {
        zh: "更新来源",
        en: "Update Source"
    },
    public18: {
        zh: "下载",
        en: "Download"
    },
    public19: {
        zh: "收起",
        en: "Fold"
    },
    public20: {
        zh: "展开",
        en: "Extend"
    },
    public21: {
        zh: "搜索",
        en: "Search"
    },
    public22: {
        zh: "威胁情报",
        en: "Threat Intelligence"
    },
    public23: {
        zh: "漏洞情报",
        en: "Vulnerability Intelligence"
    },
    public24: {
        zh: "安全资讯",
        en: "Security Information"
    },
    public25: {
        zh: "威胁组织",
        en: "Threat Actor"
    },
    public26: {
        zh: "恶意软件",
        en: "Malware"
    },
    public27: {
        zh: "利用工具",
        en: "Tools"
    },
    public28: {
        zh: "攻击模式",
        en: "Attack Pattern"
    },
    public29: {
        zh: "严重",
        en: "Critical"
    },
    public30: {
        zh: "高",
        en: "High"
    },
    public31: {
        zh: "中",
        en: "Medium"
    },
    public32: {
        zh: "低",
        en: "Low"
    },
    public33: {
        zh: "重要漏洞",
        en: "Important"
    },
    public34: {
        zh: "有缓解措施",
        en: "Mitigation"
    },
    public35: {
        zh: "有补丁",
        en: "Patches"
    },
    public36: {
        zh: "在野利用",
        en: "In The Wild"
    },
    public37: {
        zh: "应用程序",
        en: "Applicateion"
    },
    public38: {
        zh: "操作系统",
        en: "Operation System"
    },
    public39: {
        zh: "WEB应用",
        en: "Web Application"
    },
    public40: {
        zh: "数据库",
        en: "Database"
    },
    public41: {
        zh: "网络设备（交换机、路由器等网络终端设备）",
        en: "Network Devices (switches, routers, and other network end devices)"
    },
    public42: {
        zh: "安全产品",
        en: "Security Products"
    },
    public43: {
        zh: "未查询到结果",
        en: "No Results"
    },
    public44: {
        zh: "快速入口",
        en: "Quick Entry"
    },
    public45: {
        zh: "威胁情报中心",
        en: "Threat Intelligence Center"
    },
    public46: {
        zh: "威胁情报网关",
        en: "Threat Intelligence Gateway"
    },
    public47: {
        zh: "数字风险防护平台",
        en: "Digital Risk Protection Service"
    },
    public48: {
        zh: "威胁情报平台",
        en: "Threat Intelligence Platform"
    },
    public49: {
        zh: "联系我们",
        en: "Contact Us"
    },
    public50: {
        zh: "联系电话",
        en: "Phone"
    },
    public51: {
        zh: "客户服务",
        en: "Service"
    },
    public52: {
        zh: "市场合作",
        en: "Marketing"
    },
    public53: {
        zh: "合作伙伴",
        en: "Partnership"
    },
    public54: {
        zh: "天际友盟信息技术有限公司版权所有",
        en: "Tianji Partners Co., Ltd. All Rights Reserved"
    },
    public55: {
        zh: "京ICP备15055270号",
        en: "Beijing ICP 15055270"
    },
    public56: {
        zh: "关注天际友盟",
        en: "About Tianji Partners"
    },
    public57: {
        zh: "请致电",
        en: "Contact:"
    },
    public58: {
        zh: "识别二维码关注 “天际友盟” 官方公众号",
        en: "Scan the QR code to subscribe to Wechat Public Account of Tianji Partners"
    },
    public59: {
        zh: "服务邮箱",
        en: "E-Mail:"
    },
    public60: {
        zh: "相关产品入口",
        en: "Link to:"
    },
    public61: {
        zh: "数字风险防护平台 DRP",
        en: "Digital Risk Protection Platform"
    },
    public62: {
        zh: "威胁情报平台  SIC",
        en: "Threat Intelligence Center"
    },
    public63: {
        zh: "威胁情报网关  TIG",
        en: "Threat Intelligence Gateway"
    },
    public64: {
        zh: "确认",
        en: "Yes"
    },
    public65: {
        zh: "取消",
        en: "No"
    },
    public651: {
        zh: "登录",
        en: "Sign in"
    },
    public66: {
        zh: "退出",
        en: "Sing out"
    },
    public67: {
        zh: "注册",
        en: "Sign up"
    },
    public68: {
        zh: "验证码",
        en: "Captcha"
    },
    public69: {
        zh: "暂无",
        en: "None"
    },
    public70: {
        zh: "无",
        en: "None"
    },
    public71: {
        zh: "复制成功",
        en: "Copy Successful"
    },

    public72: {
        zh: "修改",
        en: "Modify"
    },
    public73: {
        zh: "请输入关键词搜索",
        en: "Please enter keywords to search."
    },
    public74: {
        zh: "复制",
        en: "Copy"
    },
    public75: {
        zh: "申请开通",
        en: "Apply"
    },
    public76: {
        zh: "暂未开通",
        en: "No Access"
    },
    public77: {
        zh: "是",
        en: "Yes"
    },

    public78: {
        zh: "否",
        en: "No"
    },
    public79: {
        zh: "申请",
        en: "Apply"
    },

    public80: {
        zh: "暂不支持查询IPv6格式数据，敬请期待！",
        en: "IPv6 querying is not supported yet."
    },
    public81: {
        zh: "本次访问被判定为爬虫行为，IP将被锁定1小时，请稍后访问！",
        en: "This visit has been identified as a robot behavior, and the IP will be blocked for 1 hour. Please try again later!"
    },

    //头部
    header1: {
        zh: "首页",
        en: "首页en首页en首页en首页en"
    },
    header2: {
        zh: "情报订阅",
        en: ""
    },
    header3: {
        zh: "情报市场",
        en: ""
    },
    header4: {
        zh: "漏洞情报",
        en: ""
    },
    header5: {
        zh: "威胁知识库",
        en: ""
    },
    header6: {
        zh: "威胁组织",
        en: ""
    },
    header7: {
        zh: "恶意软件",
        en: ""
    },
    header8: {
        zh: "利用工具",
        en: ""
    },
    header9: {
        zh: "攻击模式",
        en: ""
    },
    header10: {
        zh: "情报管理",
        en: ""
    },
    header11: {
        zh: "创建情报",
        en: ""
    },
    header12: {
        zh: "审核情报",
        en: ""
    },
    header13: {
        zh: "API中心",
        en: ""
    },
    header14: {
        zh: "API接口说明",
        en: "API Manual"
    },
    header15: {
        zh: "下载SDK",
        en: ""
    },
    header16: {
        zh: "常见问题",
        en: ""
    },
    footer1: {
        zh: "快速入口",
        en: "Quick Entry"
    },
    footer2: {
        zh: "威胁情报中心",
        en: ""
    },
    footer3: {
        zh: "威胁情报网关",
        en: ""
    },
    footer4: {
        zh: "数字风险防护平台",
        en: ""
    },
    footer5: {
        zh: "威胁情报平台",
        en: ""
    },
    footer6: {
        zh: "联系我们",
        en: "Contact Us"
    },
    footer7: {
        zh: "联系电话",
        en: ""
    },
    footer8: {
        zh: "市场合作",
        en: ""
    },
    footer9: {
        zh: "客户服务",
        en: ""
    },
    footer10: {
        zh: "合作伙伴",
        en: ""
    },
    footer11: {
        zh: "关注天际友盟",
        en: "About Tianji Partners"
    },

    //威胁知识库中英文对照表
    treatImg1: {
        zh: "组织别称",
        en: "Group Name"
    },
    treatImg2: {
        zh: "著名组织",
        en: "Famous Groups"
    },
    treatImg3: {
        zh: "目标行业",
        en: "Target Industry"
    },
    treatImg4: {
        zh: "攻击水平",
        en: "Attack Level"
    },
    treatImg5: {
        zh: "统计数据",
        en: "Statistics"
    },
    treatImg6: {
        zh: "组织档案",
        en: "Profile"
    },
    treatImg7: {
        zh: "覆盖国家",
        en: "Influence Sphere"
    },
    treatImg8: {
        zh: "查看更多",
        en: "More"
    },
    treatImg9: {
        zh: "共",
        en: "Total"
    },
    treatImg10: {
        zh: "个组织",
        en: "Groups"
    },
    treatImg11: {
        zh: "搜索条件包含组织名称/来源国家/目标行业",
        en: "Search criteria include group name, country and industry."
    },
    treatImg12: {
        zh: "欧洲",
        en: "Europe"
    },
    treatImg13: {
        zh: "亚洲",
        en: "Asia"
    },
    treatImg14: {
        zh: "非洲",
        en: "Africa"
    },
    treatImg15: {
        zh: "南美洲",
        en: "South America"
    },
    treatImg16: {
        zh: "北美洲",
        en: "North America"
    },
    treatImg17: {
        zh: "大洋洲",
        en: "Oceania "
    },
    treatImg18: {
        zh: "返回全景图",
        en: "Back"
    },
    treatImg19: {
        zh: "全部组织",
        en: "All Groups"
    },


    //威胁组织
    treator1: {
        zh: "威胁组织",
        en: "Threat Actor"
    },
    treator2: {
        zh: "搜索条件包含组织名称/来源国家/目标行业等",
        en: "Search conditions include names,source country, target industry, etc."
    },
    treator3: {
        zh: "搜索",
        en: "Search"
    },
    treator4: {
        zh: "攻击水平",
        en: "Attack Level"
    },
    treator5: {
        zh: "全部",
        en: "All"
    },
    treator6: {
        zh: "无经验",
        en: "No Experience"
    },
    treator7: {
        zh: "初级",
        en: "Minimal"
    },
    treator8: {
        zh: "中级",
        en: "Intermediate"
    },
    treator9: {
        zh: "高级",
        en: "Advanced"
    },
    treator10: {
        zh: "高级",
        en: "Advanced"
    },
    treator11: {
        zh: "专家级",
        en: "Expert"
    },
    treator12: {
        zh: "技术革新者",
        en: "Innovator"
    },
    treator13: {
        zh: "战略级",
        en: "Strategic"
    },
    treator14: {
        zh: "组织标签",
        en: "Actor Tag"
    },
    treator15: {
        zh: "激进分子",
        en: "Activist"
    },
    treator16: {
        zh: "竞争对手",
        en: "Competitor"
    },
    treator17: {
        zh: "网络罪犯",
        en: "Criminal"
    },
    treator18: {
        zh: "黑客",
        en: "Hacker"
    },
    treator19: {
        zh: "内部误操作人员",
        en: "Insider-accidental"
    },
    treator20: {
        zh: "恐怖分子",
        en: "Terrorist"
    },
    treator21: {
        zh: "内部不满人员",
        en: "Insider-disgruntled"
    },
    treator22: {
        zh: "间谍",
        en: "Spy"
    },
    treator23: {
        zh: "国家级攻击者",
        en: "Nation-state"
    },
    treator24: {
        zh: "犯罪集团",
        en: "Crime-syndicate"
    },
    treator25: {
        zh: "追求轰动效应者",
        en: "Sensationalist"
    },
    treator26: {
        zh: "来源国家",
        en: "Source Country"
    },
    treator27: {
        zh: "选择国家",
        en: "Select Country"
    },
    treator28: {
        zh: "重置",
        en: "Reset"
    },
    treator29: {
        zh: "组织别称",
        en: "Actor Alias"
    },
    treator30: {
        zh: "攻击水平",
        en: "Attack Level"
    },
    treator31: {
        zh: "目标行业",
        en: "Target Industry"
    },
    treator32: {
        zh: "指示器",
        en: "Indicator"
    },
    treator33: {
        zh: "历史活动",
        en: "History Activity"
    },
    treator34: {
        zh: "下载成功",
        en: "Download Successful"
    },
    treator35: {
        zh: "暂无别称",
        en: "No other name available"
    },
    treator36: {
        zh: "无",
        en: "None"
    },
    treator37: {
        zh: "复制成功",
        en: "Copy Successful"
    },
    treator38: {
        zh: "暂无更多数据",
        en: "No more data available"
    },
    treator39: {
        zh: "加载中...",
        en: "Loading..."
    },
    treator40: {
        zh: "搜索条件包含名称/影响平台等",
        en: "Search conditions include names, Influence sphere, etc."
    },
    treator41: {
        zh: "指攻击者与目标系统之间需要建立网络连接，才能成功实施攻击",
        en: "It refers to the necessity for an attacker to establish a network connection with the target system in order to successfully carry out an attack."
    },
    treator42: {
        zh: "指攻击者能够通过远程方式对目标系统进行攻击和控制",
        en: "It refers to the ability of an attacker to attack and control a target system remotely."
    },
    treator43: {
        zh: "攻击者在系统中实际拥有的权限",
        en: "The actual permissions that an attacker possesses within a system."
    },
    treator44: {
        zh: "攻击者为实现特定目标所需达到的最低权限要求",
        en: "The minimum permission requirement that an attacker needs to achieve in order to accomplish a specific objective."
    },
    treator45: {
        zh: "指攻击者采用的具体的方法或策略，用于实现其在目标系统上的攻击目标",
        en: "It refers to the specific methods or strategies employed by an attacker to achieve their attack objectives on the target system."
    },
    treator46: {
        zh: "指攻击者成功地绕过了目标系统上的安全措施",
        en: "It refers to the success of an attacker in bypassing the security measures on the target system."
    },
    treator47: {
        zh: "未搜索到匹配内容",
        en: "No results."
    },


    //威胁组织详情
    treatOrdeail1: {
        zh: "攻击资源级别",
        en: "Resource_level"
    },
    treatOrdeail2: {
        zh: "攻击水平",
        en: "Sophistication"
    },
    treatOrdeail3: {
        zh: "角色",
        en: "Role"
    },
    treatOrdeail4: {
        zh: "主要动机",
        en: "Primary Motive"
    },
    treatOrdeail5: {
        zh: "次要动机",
        en: "Secondary Motive"
    },
    treatOrdeail6: {
        zh: "个人动机",
        en: "Personal Motive"
    },
    treatOrdeail7: {
        zh: "组织别称",
        en: "Actor Alias"
    },
    treatOrdeail8: {
        zh: "恶意软件",
        en: "Malware"
    },
    treatOrdeail9: {
        zh: "目标",
        en: "Target"
    },
    treatOrdeail10: {
        zh: "地区",
        en: "Area"
    },
    treatOrdeail11: {
        zh: "行业",
        en: "Goals-industry"
    },
    treatOrdeail12: {
        zh: "描述",
        en: "Description"
    },
    treatOrdeail13: {
        zh: "定制工具",
        en: "Customised Tool"
    },
    treatOrdeail14: {
        zh: "指示器列表",
        en: "Indicator List"
    },
    treatOrdeail15: {
        zh: "类型",
        en: "Type"
    },
    treatOrdeail16: {
        zh: "指示器",
        en: "Indicators"
    },
    treatOrdeail17: {
        zh: "地理位置",
        en: "Location"
    },
    treatOrdeail18: {
        zh: "请输入关键词搜索",
        en: "Enter Keywords"
    },
    treatOrdeail19: {
        zh: "历史活动",
        en: "History Activity"
    },
    treatOrdeail20: {
        zh: "情报来源",
        en: "TI Source"
    },
    treatOrdeail21: {
        zh: "发布时间",
        en: "Time"
    },
    treatOrdeail22: {
        zh: "更新来源",
        en: "Update Source"
    },
    treatOrdeail23: {
        zh: "更新时间",
        en: "Updated"
    },
    treatOrdeail24: {
        zh: "下载",
        en: "Download"
    },

    //恶意软件
    malware1: {
        zh: "恶意软件",
        en: "Malware"
    },
    malware2: {
        zh: "列表模式",
        en: "List Mode"
    },
    malware3: {
        zh: "卡片模式",
        en: "Card Mode"
    },
    malware4: {
        zh: "搜索条件包含名称/执行环境/关联威胁组织等",
        en: "Search conditions include names, execution environment, relative threat actors, etc."
    },
    malware5: {
        zh: "侦查跟踪",
        en: "Reconnaissance"
    },
    malware6: {
        zh: "武器构建",
        en: "Weaponization"
    },
    malware7: {
        zh: "载荷投递",
        en: "Delivery"
    },
    malware8: {
        zh: "漏洞利用",
        en: "Exploitation"
    },
    malware9: {
        zh: "安装植入",
        en: "Installation"
    },
    malware10: {
        zh: "命令与控制",
        en: "Command & Control（C2）"
    },
    malware11: {
        zh: "目标达成",
        en: "Actions on Objective"
    },
    malware12: {
        zh: "执行环境",
        en: "Execution Environment"
    },
    malware13: {
        zh: "全部",
        en: "All"
    },
    malware14: {
        zh: "标签",
        en: "Tag"
    },
    malware15: {
        zh: "重置",
        en: "Reset"
    },
    malware16: {
        zh: "杀伤链",
        en: "Kill Chain"
    },
    malware17: {
        zh: "执行环境",
        en: "Execution Environment"
    },
    malware18: {
        zh: "执行环境",
        en: "Execution Environment"
    },
    // 恶意软件详情页
    malwaredetail1: {
        zh: "杀伤链",
        en: "Kill Chain"
    },
    malwaredetail2: {
        zh: "执行环境",
        en: "Execution Environment"
    },
    malwaredetail3: {
        zh: "版本",
        en: "Version"
    },
    malwaredetail4: {
        zh: "别称",
        en: "Alias"
    },
    malwaredetail5: {
        zh: "变种",
        en: "Variant"
    },
    malwaredetail6: {
        zh: "关联组织",
        en: "Relative Actors"
    },
    malwaredetail7: {
        zh: "利用漏洞",
        en: "Exploit Vulnerability"
    },
    malwaredetail8: {
        zh: "影响产品",
        en: "Product"
    },
    malwaredetail9: {
        zh: "漏洞描述",
        en: "Vulnerability Description"
    },
    malwaredetail10: {
        zh: "利用工具",
        en: "Tools"
    },
    malwaredetail11: {
        zh: "攻击模式",
        en: "Attack Pattern"
    },
    malwaredetail12: {
        zh: "描述",
        en: "Description"
    },
    malwaredetail13: {
        zh: "影响范围",
        en: "Influence Sphere"
    },
    malwaredetail14: {
        zh: "关联情报",
        en: "Relative TI"
    },
    malwaredetail15: {
        zh: "关联情报",
        en: "Relative TI"
    },
    malwaredetail16: {
        zh: "情报来源",
        en: "TI Source"
    },
    malwaredetail17: {
        zh: "发布时间",
        en: "Time"
    },
    malwaredetail18: {
        zh: "更新来源",
        en: "Update Source"
    },
    malwaredetail19: {
        zh: "更新时间",
        en: "Updated"
    },
    malwaredetail20: {
        zh: "下载",
        en: "Download"
    },

    //利用工具
    tools1: {
        zh: "利用工具",
        en: "Tools"
    },
    tools2: {
        zh: "列表模式",
        en: "List Mode"
    },
    tools3: {
        zh: "卡片模式",
        en: "Card Mode"
    },
    tools4: {
        zh: "搜索条件包含名称/执行环境等",
        en: "Search conditions include names, execution environment, etc."
    },
    tools5: {
        zh: "侦查跟踪",
        en: "Reconnaissance"
    },
    tools6: {
        zh: "武器构建",
        en: "Weaponization"
    },
    tools7: {
        zh: "载荷投递",
        en: "Delivery"
    },
    tools8: {
        zh: "漏洞利用",
        en: "Exploitation"
    },
    tools9: {
        zh: "安装植入",
        en: "Installation"
    },
    tools10: {
        zh: "命令与控制",
        en: "Command & Control（C2）"
    },
    tools11: {
        zh: "目标达成",
        en: "Actions on Objective"
    },
    tools12: {
        zh: "执行环境",
        en: "Execution Environment"
    },
    tools13: {
        zh: "全部",
        en: "All"
    },
    tools14: {
        zh: "标签",
        en: "Tag"
    },
    tools15: {
        zh: "重置",
        en: "Reset"
    },
    tools16: {
        zh: "杀伤链",
        en: "Kill Chain"
    },
    tools17: {
        zh: "执行环境",
        en: "Execution Environment"
    },
    //利用工具详情
    toolsdetail1: {
        zh: "杀伤链",
        en: "Kill Chain"
    },
    toolsdetail2: {
        zh: "执行环境",
        en: "Execution Environment"
    },
    toolsdetail3: {
        zh: "版本",
        en: "Version"
    },
    toolsdetail4: {
        zh: "关联组织",
        en: "Relative Actors"
    },
    toolsdetail5: {
        zh: "别称",
        en: "Alias"
    },
    toolsdetail6: {
        zh: "利用漏洞",
        en: "Exploit Vulnerability"
    },
    toolsdetail7: {
        zh: "影响产品",
        en: "Product"
    },
    toolsdetail8: {
        zh: "漏洞描述",
        en: "Vulnerability description"
    },
    toolsdetail9: {
        zh: "攻击模式",
        en: "Attack Pattern"
    },
    toolsdetail10: {
        zh: "描述",
        en: "Description"
    },
    toolsdetail11: {
        zh: "影响范围",
        en: "Influence Sphere"
    },
    toolsdetail12: {
        zh: "关联情报",
        en: "Relative TI"
    },

    //攻击模式
    attack1: {
        zh: "攻击模式",
        en: "Attack pattern"
    },
    attack2: {
        zh: "列表模式",
        en: "List Mode"
    },
    attack3: {
        zh: "卡片模式",
        en: "Card Mode"
    },
    attack4: {
        zh: "搜索条件包含名称/执行环境/关联威胁组织等",
        en: "Search conditions include names, execution environment, relative threat actors, etc."
    },
    attack5: {
        zh: "杀伤链",
        en: "Kill Chain"
    },
    attack6: {
        zh: "影响平台",
        en: "Influence Sphere"
    },
    //攻击模式详情页
    attackdetail1: {
        zh: "杀伤链",
        en: "Kill Chain"
    },
    attackdetail2: {
        zh: "影响平台",
        en: "Influence Platform"
    },
    attackdetail3: {
        zh: "需要网络",
        en: "Require Network"
    },
    attackdetail4: {
        zh: "支持远程",
        en: "Support Remote"
    },
    attackdetail5: {
        zh: "有效权限",
        en: "Effective Permission"
    },
    attackdetail6: {
        zh: "权限要求",
        en: "Permission Required"
    },
    attackdetail7: {
        zh: "战术目标",
        en: "Tactic"
    },
    attackdetail8: {
        zh: "防御绕过",
        en: "Defense Bypassed"
    },
    attackdetail9: {
        zh: "恶意软件",
        en: "Malware"
    },
    attackdetail10: {
        zh: "利用工具",
        en: "Tools"
    },
    attackdetail11: {
        zh: "描述",
        en: "Description"
    },
    attackdetail30: {
        zh: "缓解措施",
        en: "Remediation"
    },
    attackdetail12: {
        zh: "审计",
        en: "Audit"
    },
    attackdetail13: {
        zh: "执行保护",
        en: "Execution Protection"
    },
    attackdetail14: {
        zh: "限制库加载",
        en: "Restriction Library Loading"
    },
    attackdetail15: {
        zh: "检测方法",
        en: "Detection"
    },
    attackdetail16: {
        zh: "关联情报",
        en: "Relevant TI"
    },
    attackdetail17: {
        zh: "情报来源",
        en: "TI Source"
    },
    attackdetail18: {
        zh: "发布时间",
        en: "Time"
    },
    attackdetail19: {
        zh: "更新来源",
        en: "Update Source"
    },
    attackdetail20: {
        zh: "更新时间",
        en: "Updated"
    },
    attackdetail21: {
        zh: "下载",
        en: "Download"
    },
    attackdetail22: {
        zh: "未查询到结果",
        en: "No results"
    },

    //情报订阅
    subscription1: {
        zh: "情报订阅",
        en: "TI Subscription"
    },
    subscription2: {
        zh: "支持查看、使用数据集合和传感器。",
        en: "View and use to data sets and indicators supported"
    },
    subscription3: {
        zh: "集合数",
        en: "Set Number"
    },
    subscription4: {
        zh: "首先，请您建立一个或多个集合，并在每个集合下添加相应的情报来源，自动建立传感器，将集合对应到您的传感器，即可完成配置。",
        en: "First, please create one or more sets and add corresponding TI sources under each set. Then, create sensors and map the sets to your sensors to complete the configuration."
    },
    subscription5: {
        zh: "订阅功能目前仅对VIP用户开放，您暂无创建集合的使用权限，请发送邮件至service@tj-un.com进行开通。",
        en: "The subscription is open to VIPs. Unfortunately, you have no access to set creation. Please send an email to service@tj-un.com for more info."
    },
    subscription6: {
        zh: "请输入关键词搜索",
        en: "Please enter keywords"
    },
    subscription7: {
        zh: "创建集合",
        en: "Create Set"
    },
    subscription8: {
        zh: "集合名称",
        en: "Set Name"
    },
    subscription9: {
        zh: "默认集合",
        en: "Default"
    },
    subscription10: {
        zh: "类型",
        en: "Type"
    },
    subscription11: {
        zh: "传感器数量",
        en: "Sensor Amt."
    },
    subscription12: {
        zh: "情报数量",
        en: "TI Amt."
    },
    subscription13: {
        zh: "指示器数量",
        en: "IOCs Amt."
    },
    subscription14: {
        zh: "有效期",
        en: "Valid Period"
    },
    subscription15: {
        zh: "数据范围",
        en: "Data Scale"
    },
    subscription16: {
        zh: "操作",
        en: "Operation"
    },
    subscription17: {
        zh: "自有",
        en: "User-Defined"
    },
    subscription18: {
        zh: "公共",
        en: "Public"
    },
    subscription19: {
        zh: "生成中...",
        en: "Generating"
    },
    subscription20: {
        zh: "详情",
        en: "Detail"
    },
    subscription21: {
        zh: "修改",
        en: "Modify"
    },
    subscription22: {
        zh: "删除",
        en: "Delete"
    },
    subscription23: {
        zh: "删除",
        en: "Delete"
    },
    subscription241: {
        zh: "删除名称为",
        en: "Are you sure to"
    },
    subscription242: {
        zh: "的集合？",
        en: "delete this set?"
    },
    subscription25: {
        zh: "传感器",
        en: "Sensor"
    },
    subscription26: {
        zh: "请您建立一个传感器，每个传感器将生成一个token码(即API key)，每个传感器的token码都是唯一的，它将应用于您的API数据请求中。",
        en: "Please create a sensor. Each sensor will generate a token code (API key). The token of each sensor is unique and will be used in your API data requests."
    },
    subscription27: {
        zh: "您暂无创建传感器的使用权限，请发送邮件至service@tj-un.com进行开通。",
        en: "Access denied to create indicators. Please contact service@tj-un.com."
    },
    subscription28: {
        zh: "请输入类型、集合名称、标签进行搜索",
        en: "Please input sensor name, set name, description"
    },
    subscription29: {
        zh: "创建传感器",
        en: "Create Sensor"
    },
    subscription30: {
        zh: "传感器名称",
        en: "Sensor Name"
    },
    subscription31: {
        zh: "描述",
        en: "Description"
    },
    subscription32: {
        zh: "提交",
        en: "Submit"
    },
    subscription33: {
        zh: "提交",
        en: "Valid Period"
    },
    subscription34: {
        zh: "token",
        en: "Token"
    },
    subscription35: {
        zh: "数据范围",
        en: "Data Scale"
    },
    subscription36: {
        zh: "操作",
        en: "Operation"
    },
    subscription37: {
        zh: "刷新token",
        en: "Refresh Token"
    },
    subscription38: {
        zh: "token刷新成功",
        en: "Token Refreshed"
    },
    subscription39: {
        zh: "查看数据",
        en: "View Data"
    },
    subscription40: {
        zh: "修改",
        en: "Modify"
    },
    subscription41: {
        zh: "删除",
        en: "Delete"
    },
    subscription42: {
        zh: "可用",
        en: "Available"
    },
    subscription43: {
        zh: "可用",
        en: "Available"
    },
    subscription44: {
        zh: "不可用",
        en: "Unavailable"
    },
    subscription45: {
        zh: "token码",
        en: "Token"
    },
    subscription46: {
        zh: "传感器名称",
        en: "Sensor Name"
    },
    subscription47: {
        zh: "是否可用",
        en: "Applicable"
    },
    subscription48: {
        zh: "所属集合",
        en: "Belong Set"
    },
    subscription49: {
        zh: "描述",
        en: "Description"
    },
    subscription50: {
        zh: "提交",
        en: "Submit"
    },
    subscription51: {
        zh: "确认删除传感器",
        en: "Make Sure To Delete Indicator"
    },
    subscription52: {
        zh: "token码",
        en: "Token"
    },
    subscription53: {
        zh: "传感器名称",
        en: "Sensor Name"
    },
    subscription54: {
        zh: "基本信息",
        en: "Basic Information"
    },
    subscription55: {
        zh: "创建时间",
        en: "Creation Time"
    },
    subscription56: {
        zh: "最后修改时间",
        en: "Last Modification Time"
    },
    subscription57: {
        zh: "有效日期",
        en: "Valid Period"
    },
    subscription58: {
        zh: "情报数量",
        en: "TI Amt."
    },
    subscription59: {
        zh: "指示器数量",
        en: "IOCs Amt."
    },
    subscription60: {
        zh: "集合描述",
        en: "Set Description"
    },
    subscription61: {
        zh: "情报卡片",
        en: "TI Cards"
    },
    subscription62: {
        zh: "情报",
        en: "TI"
    },
    subscription63: {
        zh: "请输入关键词搜索",
        en: "Please enter keywords"
    },
    subscription64: {
        zh: "请按威胁类型选择",
        en: "Select By Threat Type"
    },
    subscription65: {
        zh: "指示器",
        en: "Indicator"
    },
    subscription66: {
        zh: "请输入指示器值",
        en: "Enter Indicator Value"
    },
    subscription67: {
        zh: "传感器",
        en: "Sensor"
    },
    subscription68: {
        zh: "返回上一级",
        en: "Back"
    },
    subscription69: {
        zh: "切换至标签订阅模式",
        en: "Switch To Tag Subscription"
    },
    subscription70: {
        zh: "下一步",
        en: "Next"
    },
    subscription71: {
        zh: "填写集合信息",
        en: "Enter Set Profile"
    },
    subscription72: {
        zh: "集合名称",
        en: "Set Name"
    },
    subscription73: {
        zh: "集合名称已存在",
        en: "Set Name Already Exists"
    },
    subscription74: {
        zh: "确认删除传感器",
        en: "Make Sure To Delete Indicator"
    },
    subscription75: {
        zh: "token码",
        en: "Token"
    },
    subscription76: {
        zh: "基本信息",
        en: "Basic Information"
    },
    subscription77: {
        zh: "最后修改时间",
        en: "Last Modification Time"
    },
    subscription78: {
        zh: "有效日期",
        en: "Valid Period"
    },
    subscription79: {
        zh: "情报数量",
        en: "TI Amt."
    },
    subscription80: {
        zh: "指示器数量",
        en: "IOCs Amt."
    },
    subscription81: {
        zh: "集合描述",
        en: "Set Description"
    },
    subscription82: {
        zh: "情报卡片",
        en: "TI Cards"
    },
    subscription83: {
        zh: "情报卡片",
        en: "TI Cards"
    },
    subscription84: {
        zh: "请输入指示器值",
        en: "Enter Indicator Value"
    },
    subscription85: {
        zh: "请输入指示器值",
        en: "Enter Indicator Value"
    },
    subscription86: {
        zh: "切换至标签订阅模式",
        en: "Switch To Tag Subscription"
    },
    subscription87: {
        zh: "切换至标签订阅模式",
        en: "Switch To Tag Subscription"
    },
    subscription88: {
        zh: "填写集合信息",
        en: "Enter Set Profile"
    },
    subscription89: {
        zh: "集合名称已存在",
        en: "Set Name Already Exists"
    },
    subscription90: {
        zh: "请输入",
        en: "Please enter"
    },
    subscription91: {
        zh: "输入含有非法字符",
        en: "Invalid Symbol"
    },
    subscription92: {
        zh: "选择卡片",
        en: "Select Card"
    },
    subscription93: {
        zh: "状态",
        en: "Status"
    },
    subscription94: {
        zh: "全部",
        en: "All"
    },
    subscription95: {
        zh: "全部",
        en: "All"
    },
    subscription96: {
        zh: "已订阅",
        en: "Subscribed"
    },
    subscription97: {
        zh: "可购买",
        en: "Available"
    },
    subscription98: {
        zh: "分类",
        en: "Category"
    },
    subscription99: {
        zh: "默认",
        en: "Default"
    },
    subscription100: {
        zh: "上架时间",
        en: "Launch Time"
    },
    subscription101: {
        zh: "按订阅量",
        en: "By Number Of Subcribers"
    },
    subscription102: {
        zh: "人读情报",
        en: "PRTI"
    },
    subscription103: {
        zh: "机读情报",
        en: "MRTI"
    },
    subscription104: {
        zh: "为您找到xx个情报卡片/已选择x个",
        en: "Xx Results/ Xx Seleted"
    },
    subscription105: {
        zh: "返回上一级",
        en: "Back"
    },
    subscription106: {
        zh: "切换至卡片订阅模式",
        en: "Switch To Card Subscription"
    },
    subscription107: {
        zh: "保存",
        en: "Save"
    },
    subscription108: {
        zh: "填写集合信息",
        en: "Enter Set Profile"
    },
    subscription109: {
        zh: "请输入集合的名称",
        en: "Please Input Set Name"
    },
    subscription110: {
        zh: "集合名称已存在",
        en: "Set Name Already Exists"
    },
    subscription111: {
        zh: "输入含有非法字符",
        en: "Invalid Symbol"
    },
    subscription112: {
        zh: "筛选标签",
        en: "Filter by tags"
    },
    subscription113: {
        zh: "选择标签",
        en: "Select Tags"
    },
    subscription114: {
        zh: "常用",
        en: "Common"
    },
    subscription115: {
        zh: "已选",
        en: "Selected"
    },
    subscription116: {
        zh: "行业",
        en: "Industry"
    },
    subscription117: {
        zh: "情报来源",
        en: "TI Source"
    },
    subscription118: {
        zh: "请选择",
        en: "Please Select"
    },
    subscription119: {
        zh: "发布时间",
        en: "Time"
    },
    subscription120: {
        zh: "开始日期",
        en: "Start Date"
    },
    subscription121: {
        zh: "结束日期",
        en: "End Date"
    },
    subscription122: {
        zh: "更新时间",
        en: "Updated"
    },
    subscription123: {
        zh: "命中情报",
        en: "Match TI"
    },
    subscription124: {
        zh: "按标签和过滤条件自动更新集合列表",
        en: "Automatic update of set list by tags and filters"
    },
    subscription125: {
        zh: "标题",
        en: "Title"
    },
    subscription126: {
        zh: "来源",
        en: "Source"
    },
    subscription127: {
        zh: "标签",
        en: "Tag"
    },
    subscription128: {
        zh: "数据范围",
        en: "Data Scale"
    },
    subscription129: {
        zh: "排除",
        en: "Exclude"
    },
    subscription1291: {
        zh: "取消排除",
        en: "Cancel"
    },
    subscription130: {
        zh: "提示",
        en: "Note"
    },
    subscription131: {
        zh: "确定取消创建，取消之后不保存当前创建数据。",
        en: "Are you sure to cancel? This page will not be saved."
    },
    subscription132: {
        zh: "即将切换至标签订阅模式，当前页面中的订阅条件将自动清除，是否继续？",
        en: "Are you sure to  switch to the tag subscription when the current subscription conditions will be cleared?"
    },
    subscription133: {
        zh: "即将切换至经典卡片订阅模式，当前页面中的订阅条件将自动清除，是否继续？",
        en: "Are you sure to  switch to the classic card subscription when the current subscription conditions will be cleared?"
    },
    subscription134: {
        zh: "请为该集合订阅卡片",
        en: "Please select cards for this set."
    },
    subscription135: {
        zh: "自有集合最多支持创建XX个，您已超限，如遇问题请您与我们联系。",
        en: "You have created maximum number of user-defined sets. Please contact us if you have any problems."
    },
    subscription136: {
        zh: "xx集合创建成功",
        en: "Set Creation Success"
    },
    subscription137: {
        zh: "当前集合尚未添加标签，集合中不包含任何情报数据，后续可通过单条情报订阅方式向此集合中添加情报数据，是否继续保存？",
        en: "This set has no tags and no intelligence data neither.  Intelligence data can be collected in this set by subscribing to a single intelligence card. Are you sure to save the set?"
    },
    subscription138: {
        zh: "当前集合尚未添加卡片，集合中不包含任何情报数据，后续可通过单条情报订阅方式向此集合中添加情报数据，是否继续保存？",
        en: "This set has no card and no intelligence data neither.  Intelligence data can be collected in this set by subscribing to a single intelligence card. Are you sure to save the set?"
    },
    subscription139: {
        zh: "删除传感器后,其对应的token码将不可用,确认删除吗?",
        en: "Are you sure to delete this sensor when the relevant tokens will be disabled？"
    },
    subscription140: {
        zh: "参数错误，修改传感器失败",
        en: "Wrong parameter. Failed to edit sensor."
    },
    subscription141: {
        zh: "可用于溯源查询API请求",
        en: "Applicable for API requests in trace query"
    },
    subscription142: {
        zh: "溯源查询",
        en: "TI Lookup"
    },
    subscription143: {
        zh: "刷新成功",
        en: "Successfully Refreshed"
    },
    subscription144: {
        zh: "删除成功",
        en: "Successfully Deleted"
    },
    subscription145: {
        zh: "确认删除此传感器",
        en: "Confirm deletion of this sensor"
    },
    subscription146: {
        zh: "确定",
        en: "Confirm"
    },
    subscription1461: {
        zh: "取消",
        en: "Cancel"
    },
    subscription147: {
        zh: "传感器最多支持创建 ",
        en: "The maximum number of sensors that can be created is "
    },
    subscription148: {
        zh: "个，您已超限，如遇问题请您与我们联系 ",
        en: "and you have exceeded this limit. If you encounter any issues, please contact us."
    },
    subscription149: {
        zh: "请选择所属集合",
        en: "Please select the belonging set."
    },
    subscription150: {
        zh: "最多支持输入20个字符",
        en: "Supports a maximum input of 20 characters."
    },
    subscription151: {
        zh: "输入中有非法字符",
        en: "There are illegal characters in the input."
    },
    subscription152: {
        zh: "创建成功",
        en: "Creation Successful"
    },
    subscription153: {
        zh: "传感器名称已存在",
        en: "Sensor name already exists."
    },
    subscription154: {
        zh: "修改传感器",
        en: "Modify sensor"
    },
    subscription155: {
        zh: "修改成功",
        en: "Modification Successful"
    },
    subscription156: {
        zh: "自有集合最多支持创建",
        en: "A maximum of"
    },
    subscription157: {
        zh: "个，您已超限，如遇问题请您与我们联系。",
        en: "self-owned sets can be created. You have exceeded the limit. If you encounter any issues, please contact us."
    },
    subscription158: {
        zh: "复制成功",
        en: "Copy Successful"
    },
    subscription159: {
        zh: "无",
        en: "None"
    },
    subscription160: {
        zh: "确定取消创建，取消之后不保存当前创建数据。",
        en: "Are you sure to cancel? This page will not be saved."
    },
    subscription161: {
        zh: "请输入传感器名称",
        en: "Please enter the sensor name"
    },
    subscription162: {
        zh: "关闭",
        en: "Close"
    },
    subscription163: {
        zh: "已过期",
        en: "Expired"
    },
    subscription164: {
        zh: "评定时间",
        en: "Estimation Time"
    },
    subscription165: {
        zh: "地理位置",
        en: "Geographical location"
    },
    subscription166: {
        zh: "暂无",
        en: "None"
    },
    subscription167: {
        zh: "可订阅",
        en: "Subscribe"
    },
    subscription168: {
        zh: "请输入集合名称",
        en: "Please enter the set name"
    },
    subscription169: {
        zh: "修改失败",
        en: "Failed to modify"
    },
    subscription170: {
        zh: "编辑传感器",
        en: "Edit Sensor"
    },
    subscription171: {
        zh: "创建失败",
        en: "Creation Failed"
    },
    subscription172: {
        zh: "您已超限，如遇问题请您与我们联系",
        en: "You have exceeded the limit. If you encounter any problems, please contact us."
    },
    subscription173: {
        zh: "编辑集合",
        en: "Edit Set"
    },
    subscription174: {
        zh: "含有非法字符",
        en: "Illegal characters detected in input."
    },
    subscription175: {
        zh: "请输入关键字搜索",
        en: "Please enter keywords to search."
    },
    subscription176: {
        zh: "订阅",
        en: "Subscribe"
    },
    subscription177: {
        zh: "黑客组织",
        en: "Theart Actor"
    },
    subscription178: {
        zh: "威胁分类",
        en: "Category"
    },
    subscription179: {
        zh: "攻击目标",
        en: "Target"
    },
    subscription180: {
        zh: "攻击模式",
        en: "Attack Pattern"
    },
    subscription181: {
        zh: "搜索结果",
        en: "Search results"
    },
    subscription182: {
        zh: "即将切换至卡片订阅模式，当前页面中的订阅条件将自动清除，是否继续？",
        en: "The subscription conditions in the current page will be cleared if card mode is on. Are you sure to continue？"
    },
    subscription183: {
        zh: "含有非法字符",
        en: "Invalid characters contained"
    },
    subscription184: {
        zh: "请输入关键字搜索",
        en: "Please enter keywords to search."
    },
    subscription185: {
        zh: "请选择标签",
        en: "Please select a tag."
    },
    subscription186: {
        zh: "企业链接",
        en: "Company Link"
    },
    subscription187: {
        zh: "高级查询",
        en: "Advanced Search"
    },
    subscription188: {
        zh: "订阅功能目前仅对付费用户开放，您暂无创建集合、创建传感器的使用权限，开通服务可联系我们。",
        en: "The subscription feature is currently only available to VIP members. You currently do not have permission to create sets or sensors. To activate the service, please contact us."
    },
    subscription189: {
        zh: "申请开通",
        en: "Apply Now"
    },
    subscription190: {
        zh: "首先，请您建立一个或多个集合，创建集合支持【卡片订阅模式】和【标签订阅模式】两种模式切换，在不同模式下完成集合创建后，自动建立传感器，将集合对应到您的传感器，即可完成配置。",
        en: 'Firstly, please create one or more sets. The set creation process supports switching between two modes: "Card Subscription Mode" and "Tag Subscription Mode". Once you have completed creating the set, a sensor will be automatically established, and the configuration will be completed.'
    },
    subscription191: {
        zh: "全选",
        en: 'All'
    },
    subscription192: {
        zh: "请选择标签类型",
        en: 'Please select the tag type first'
    },
    subscription193: {
        zh: "抱歉，标签超过最大限制",
        en: 'Sorry, the number of tags exceeds the maximum limit.'
    },


    //情报市场
    market1: {
        zh: "情报市场",
        en: "TI Market"
    },
    market2: {
        zh: "请输入关键词进行搜索",
        en: "Please enter keywords"
    },
    market3: {
        zh: "未查询到结果",
        en: "No result."
    },
    market4: {
        zh: "未查询到结果",
        en: "No result."
    },
    market5: {
        zh: "情报卡片",
        en: "TI Cards"
    },
    market6: {
        zh: "来源",
        en: "Source"
    },
    market7: {
        zh: "分类",
        en: "Types"
    },
    market8: {
        zh: "状态",
        en: "Status"
    },
    market9: {
        zh: "全部",
        en: "All"
    },
    market10: {
        zh: "可订阅",
        en: "Subscribe"
    },
    market11: {
        zh: "可购买",
        en: "Purchase"
    },
    market12: {
        zh: "默认",
        en: "Default"
    },
    market13: {
        zh: "上架时间",
        en: "Launch Time"
    },
    market14: {
        zh: "按订阅量",
        en: "By Subscriptions"
    },
    market15: {
        zh: "API",
        en: "API"
    },
    market16: {
        zh: "人读情报",
        en: "PRTI（People-Readable Threat Intelligence）"
    },
    market17: {
        zh: "机读情报",
        en: "MRTI（Machine-Readable Threat Intelligence）"
    },
    market18: {
        zh: "为您找到xx个情报卡片",
        en: "XX results are found."
    },
    market19: {
        zh: "分类",
        en: "Category"
    },
    market20: {
        zh: "可订阅",
        en: "Subscribe"
    },
    market21: {
        zh: "可购买",
        en: "Purchase"
    },
    market22: {
        zh: "查看",
        en: "View"
    },
    market23: {
        zh: "购买",
        en: "Purchase"
    },
    market24: {
        zh: "订阅",
        en: "Subscribe"
    },
    market25: {
        zh: "更新周期",
        en: "Frequency"
    },
    market26: {
        zh: "实时更新",
        en: "Real-time Update"
    },
    market27: {
        zh: "所属集合",
        en: "Associated Set"
    },
    market28: {
        zh: "数据说明/样例",
        en: "Data Description / Sample"
    },
    market29: {
        zh: "来源及介绍",
        en: "Source And Introduction"
    },
    market30: {
        zh: "请选择该情报数据所属的订阅集合：",
        en: "Please select the set to which this piece of TI is associated："
    },
    market31: {
        zh: "请填写如下联系信息，确认申请后，将会有相应市场人员与您沟通相应商务流程。<br/>如有疑问，可咨询 <span> (+86) 400-0810-700</span> 或 <span>service@tj-un.com</span>",
        en: "Please fill in the following information, and then our staff will contact you soon. <br/>If you have any questions, please call<span>(+86) 400-0810-700</span> or write an email to <span>service@tj-un.com</span>."
    },
    market32: {
        zh: "手机号",
        en: "Cell Phone Number"
    },
    market33: {
        zh: "请输入正确的手机号",
        en: "Please enter a valid cell phone number."
    },
    market34: {
        zh: "邮箱",
        en: "E-mail Address"
    },
    market35: {
        zh: "请输入正确的邮箱",
        en: "Please enter a valid E-mail address."
    },
    market36: {
        zh: "备注",
        en: "Notes"
    },
    market37: {
        zh: "数据集合",
        en: "Data Set"
    },
    market38: {
        zh: "适用场景",
        en: "Suitable Scenarios"
    },
    market39: {
        zh: "适用于防火墙/网关类产品",
        en: "Applicable to products, like firewalls and gateway, etc."
    },
    market40: {
        zh: "适用于DNS类产品",
        en: "Applicable to products, like DNS, etc."
    },
    market41: {
        zh: "适用于EDR类产品",
        en: "Applicable to products, like EDR, etc."
    },
    market42: {
        zh: "适用于安全分析平台",
        en: "Applicable to products, like security analytics platform, etc."
    },
    market43: {
        zh: "挖矿专项",
        en: "Cryptomining"
    },
    market44: {
        zh: "数据跨境",
        en: "Cross-border Data"
    },
    market45: {
        zh: "其他",
        en: "Other"
    },
    market46: {
        zh: "状态",
        en: "Status"
    },
    market47: {
        zh: "可购买",
        en: "Purchase"
    },
    market48: {
        zh: "可订阅",
        en: "Subscribe"
    },
    market49: {
        zh: "已订阅",
        en: "Subscribed"
    },
    market491: {
        zh: "默认",
        en: "Default"
    },
    market50: {
        zh: "按订阅量",
        en: "By Number Of Subscribers"
    },
    market51: {
        zh: "人读情报",
        en: "PRTI（People-Readable Threat Intelligence）"
    },
    market52: {
        zh: "机读情报",
        en: "MRTI（Machine-Readable Threat Intelligence）"
    },
    market53: {
        zh: "查看",
        en: "View"
    },
    market54: {
        zh: "申请集合",
        en: "Apply For Set"
    },
    market55: {
        zh: "订阅集合",
        en: "Subscribe Set"
    },
    market56: {
        zh: "适用场景",
        en: "Applicable Scenarios"
    },
    market57: {
        zh: "威胁类型",
        en: "Threat Type"
    },
    market58: {
        zh: "情报数据",
        en: "TI Data"
    },
    market59: {
        zh: "订阅成功",
        en: "Subscription Success"
    },
    market60: {
        zh: "成功",
        en: "Success"
    },
    market61: {
        zh: "您的申请我们已收到，将尽快与您联系。您也可以致电详询：（+86）400-0810-700。",
        en: "We have received your application and will respond soon. Or feel free to call  +86 400-0810-700."
    },
    market62: {
        zh: "我知道了",
        en: "OK"
    },
    market63: {
        zh: "北京天际友盟信息技术有限公司（简称 天际友盟），是中国首家专注于威胁情报聚合、分析、交换的独立第三方专业服务提供商，致力于推动和建设独立的覆盖全球威胁情报的生态系统。 我们为伙伴提供战术、战役、战略层面的威胁情报分析和支持，构建网络安全威胁情报汇集、分析和交换共享的技术平台，并与合作伙伴一起向客户提供以情报为主导的信息安全增值服务和行业解决方案。",
        en: "TianJi Partners co., Ltd., referred as Tianji, is China's first independent third-party professional service provider focusing on threat intelligence aggregation, analysis, and exchange. It is committed to promoting and building a global threat intelligence eco-system. Tianji provides business partners with threat intelligence analysis and support at the tactical, operational, and strategic levels, building a technical platform for network security threat intelligence collection, analysis, exchange and sharing, and collaborating with partners to provide customers with intelligence-led information security value-added services and solutions."
    },
    market64: {
        zh: "济南互信软件技术有限公司2011年成立于济南(公司前身为2007年成立的济南互信互通信息技术有限公司)，在北京、天津、广州、西安、南昌等地设有驻地服务机构，公司致力于信息安全软件的自主研发和服务，在网络信息安全领域有多年的专业技术积累。历年来，公司技术团队多次深度参与国家级网络安全监管部门组织的重大网络安全事件的监测分析和取证溯源工作，具有丰富的重大安全事件分析取证经验。尤其在对美国NSA、CIA等APT组织的对抗工作方面，从监测发现、分析取证到解密溯源等各个环节均有较为成熟的技术方案。",
        en: "Jinan Huxin Software Technology Co., Ltd. was established in Jinan in 2011,  formerly as Jinan Huxin Information Technology Co., Ltd. established in 2007. Huxin has agencies and branches in Beijing, Tianjin, Guangzhou, Xi'an, Nanchang and other cities. The company, in accordance with many years of professional and technical accumulation, is committed to self-developed research and services in information security software. Over the years, the company's technical team has acquired experiences  in activities of the monitoring, analysis and evidence collection, due to the deep involvement  in major network security incidents researches organized by national regulatory authorities. Especially in handling APT organizations such as the US NSA and CIA, there are relatively mature solutions in all aspects from monitoring, evidence collection, decryption and traceability, etc."
    },
    market65: {
        zh: "Rapid7 是全球领先的安全风险信息解决方案提供商，Rapid7 用来漏洞管理,漏洞扫描,漏洞评估和渗透测试。",
        en: "Rapid7 is the world's leading provider of security risk information solutions for vulnerability management, vulnerability scanning, vulnerability assessment and penetration testing."
    },
    market66: {
        zh: "IBM,全球性的信息技术和业务解决方案公司。IBM正利用认知技术,大数据分析和云服务,助力各行业重铸商业模式。",
        en: "IBM, with the assitance of cognitive technology, big data analysis and cloud services to help various industries reshape business models, is a global information technology and business solutions company."
    },
    market67: {
        zh: "360网络安全研究院，致力于通过大数据分析来解读网络安全事件。",
        en: "360Netlab is committed to interpreting cyber security incidents through big data analysis."
    },
    market68: {
        zh: "火绒专注于终端安全(PC、手机等),拥有领先的安全核心技术、EDR运营体系和成熟的产品,能够有效帮助个人和机构(政府、企业等)防御病毒、黑客和流氓软件的侵害。",
        en: "Huorong focuses on terminal security, such as PCs, mobile phones, etc. The company has leading security core technologies, EDR operation systems and mature products, which can effectively help individuals, governments deartments and enterprises defend against viruses, hackers and rogue softwares, etc."
    },
    market69: {
        zh: "网宿科技 始创于 2000 年 1 月，主要提供互联网内容分发与加速(CDN)、云计算、云安全、全 球分布式数据中心(IDC) 等服务。 网宿“云Web应用防火墙（Web Application Firewall，简称WAF）”为“网宿网盾”安全品牌旗下一款为Web应用提供保护的产品，不需要客户端部署软硬件，即可享受针对SQL注入、XSS、CSRF等OWASP TOP10中的WEB应用安全威胁及目录遍历、网站扫描等其它安全威胁的防护，从而减少网站出现拖库、篡改、机密信息泄漏等安全风险。",
        en: "Wangsu Technology was founded in January 2000. It mainly provides Internet content distribution and CDN acceleration, cloud computing, cloud security, global distributed data center (IDC) and other services. 'Web Application Firewall (WAF)' is a product under the 'Wangsu Netshield' security brand to provides protection for web applications. It does not require clients to deploy software and hardware to acquire protection dedicated to SQL jinjection, XSS, CSRF and other WEB application security threats listed in the OWASP TOP10, as well as other threats such as directory traversal and website scanning.This protection reduces risks such as dragging, tampering, and leakage of confidential information on the website."
    },
    market70: {
        zh: "奇安信专注于网络空间安全市场，向政府、企业用户提供新一代企业级网络安全产品和服务。",
        en: "QAX focuses on the cyberspace security marketis and is dedicated to providing a new version of enterprise-level network security products and services to government and corporate users. "
    },
    market71: {
        zh: "奇安信专注于网络空间安全市场，向政府、企业用户提供新一代企业级网络安全产品和服务。",
        en: "QAX focuses on the cyberspace security marketis and is dedicated to providing a new version of enterprise-level network security products and services to government and corporate users. "
    },
    market72: {
        zh: "指示器数据",
        en: "Indicator Data"
    },
    market73: {
        zh: "简要描述",
        en: "Description"
    },
    market74: {
        zh: "暂无数据",
        en: "No data available"
    },
    market75: {
        zh: "序号",
        en: "No."
    },

    



    //漏洞情报
    vulnerability1: {
        zh: "漏洞情报",
        en: "Vulnerability"
    },
    vulnerability2: {
        zh: "搜索条件包含漏洞关键词/影响产品/厂商/CVE/CNVD/CNNVD编号",
        en: "Search criteria includes vulnerability keywords/products/vendors/CVE/CNVD/CNNVD ID"
    },
    vulnerability3: {
        zh: "搜索",
        en: "Search"
    },
    vulnerability4: {
        zh: "热门搜索",
        en: "Trends"
    },
    vulnerability5: {
        zh: "信息不存在或您没有访问权限",
        en: "No results or access denied."
    },
    vulnerability6: {
        zh: "高级搜索",
        en: "Advanced Search"
    },
    vulnerability7: {
        zh: "披露时间",
        en: "Publicly"
    },
    vulnerability8: {
        zh: "更新时间",
        en: "Updated"
    },
    vulnerability9: {
        zh: "漏洞等级",
        en: "Vulnerability Level"
    },
    vulnerability10: {
        zh: "严重",
        en: "Critical"
    },
    vulnerability11: {
        zh: "高",
        en: "High"
    },
    vulnerability12: {
        zh: "中",
        en: "Medium"
    },
    vulnerability13: {
        zh: "低",
        en: "Low"
    },
    vulnerability14: {
        zh: "在野利用",
        en: "Exploited"
    },
    vulnerability15: {
        zh: "缓解措施",
        en: "Remediation"
    },

    vulnerability16: {
        zh: "请选择",
        en: "Select"
    },
    vulnerability17: {
        zh: "有/无",
        en: "Y/NA"
    },
    vulnerability171: {
        zh: "有",
        en: "Yes"
    },
    vulnerability172: {
        zh: "无",
        en: "No"
    },
    vulnerability18: {
        zh: "查询",
        en: "Search"
    },
    vulnerability19: {
        zh: "重置",
        en: "Reset"
    },
    vulnerability20: {
        zh: "重要漏洞",
        en: "Critical vulnerability"
    },
    vulnerability21: {
        zh: "严重",
        en: "Critical"
    },
    vulnerability22: {
        zh: "未查询到结果",
        en: "No Results"
    },
    vulnerability23: {
        zh: "你可能想看",
        en: "You may be interested in"
    },
    vulnerability24: {
        zh: "暂无相关情报",
        en: "No Relevant TI"
    },
    vulnerability25: {
        zh: "下载",
        en: "Download"
    },
    vulnerability26: {
        zh: "漏洞描述",
        en: "Vulnerability Description"
    },
    vulnerability27: {
        zh: "重要漏洞",
        en: "Important"
    },
    vulnerability28: {
        zh: "在野利用",
        en: "Exploits In The Wild"
    },
    vulnerability29: {
        zh: "有缓解措施",
        en: "Mitigation"
    },
    vulnerability30: {
        zh: "有补丁",
        en: "Patches"
    },
    vulnerability31: {
        zh: "应用程序",
        en: "Applicateion"
    },
    vulnerability32: {
        zh: "操作系统",
        en: "Operation System"
    },
    vulnerability33: {
        zh: "WEB应用",
        en: "Web Application"
    },
    vulnerability34: {
        zh: "数据库",
        en: "Database"
    },
    vulnerability35: {
        zh: "网络设备（交换机、路由器等网络终端设备）",
        en: "Network Devices (switches, routers, and other network end devices)"
    },
    vulnerability36: {
        zh: "安全产品",
        en: "Security Products"
    },
    vulnerability361: {
        zh: "基本信息",
        en: "Basic Information"
    },
    vulnerability37: {
        zh: "漏洞类型",
        en: "CWE"
    },
    vulnerability38: {
        zh: "披露时间",
        en: "Disclosure Time"
    },
    vulnerability39: {
        zh: "更新时间",
        en: "Updated"
    },
    vulnerability40: {
        zh: "CVE编号",
        en: "CVE_ID"
    },
    vulnerability41: {
        zh: "CNVD编号",
        en: "CNVD_ID"
    },
    vulnerability42: {
        zh: "CNNVD编号",
        en: "CNNVD_ID"
    },
    vulnerability43: {
        zh: "漏洞补丁",
        en: "Patch"
    },
    vulnerability44: {
        zh: "涉及行业",
        en: "Industry"
    },
    vulnerability45: {
        // zh: "CVSS V2.X 基础评分",
        // en: "CVSS V2.X "
        zh: "CVSS V2 基础评分",
        en: "CVSS V2"
    },
    vulnerability46: {
        // zh: "CVSS V3.X 基础评分",
        // en: "CVSS V3.X"
        zh: " 基础评分",
        en: ""
    },
    vulnerability47: {
        zh: "受影响厂商/产品",
        en: "Provider Or Product Affected"
    },
    vulnerability48: {
        zh: "涉及厂商",
        en: "Provider"
    },
    vulnerability49: {
        zh: "受影响产品",
        en: "Product"
    },
    vulnerability50: {
        zh: "目标CPE",
        en: "CPE"
    },
    vulnerability51: {
        zh: "展开",
        en: "Extend"
    },
    vulnerability52: {
        zh: "收起",
        en: "Fold"
    },
    vulnerability53: {
        zh: "链接",
        en: "Link"
    },
    vulnerability54: {
        zh: "来源",
        en: "Source"
    },
    vulnerability55: {
        zh: "修复建议",
        en: "Remediation"
    },
    vulnerability56: {
        zh: "参考链接",
        en: "Link"
    },
    vulnerability57: {
        zh: "关联情报",
        en: "Relevant TI"
    },
    vulnerability58: {
        zh: "漏洞评分",
        en: "Score"
    },
    vulnerability59: {
        zh: "最新漏洞",
        en: "The Latest Vulnerability"
    },
    vulnerability60: {
        zh: "严重/高/中/低",
        en: "Critical/High/Medium/Low"
    },
    vulnerability61: {
        zh: "暂无CVE",
        en: "CVE Unavailable"
    },
    vulnerability62: {
        zh: "暂无CVE",
        en: "CVE Unavailable"
    },
    vulnerability63: {
        zh: "匹配漏洞",
        en: "Matched Vulnerability"
    },

    vulnerability64: {
        zh: "配置容器",
        en: "Configuration"
    },
    vulnerability65: {
        zh: "运行环境依赖",
        en: "Running on/with"
    },
    vulnerability66: {
        zh: "小于",
        en: "Up to (excluding)"
    },
    vulnerability67: {
        zh: "小于等于",
        en: "Up to (including)"
    },
    vulnerability68: {
        zh: "大于",
        en: "From (excluding)"
    },
    vulnerability69: {
        zh: "大于等于",
        en: "From (including)"
    },


    //首页
    home1: {
        zh: "首页",
        en: "Home"
    },
    home2: {
        zh: "情报订阅",
        en: "TI Subscription"
    },
    home3: {
        zh: "情报市场",
        en: "TI Market"
    },
    home4: {
        zh: "漏洞情报",
        en: "Vulnerability"
    },
    home5: {
        zh: "威胁知识库",
        en: "TI Lab"
    },
    home6: {
        zh: "威胁组织",
        en: "Threat Actor"
    },
    home7: {
        zh: "恶意软件",
        en: "Malware"
    },
    home8: {
        zh: "利用工具",
        en: "Tools"
    },
    home9: {
        zh: "攻击模式",
        en: "Attack Pattern"
    },
    home10: {
        zh: "情报管理",
        en: "Management"
    },
    home11: {
        zh: "创建情报",
        en: "TI Creation"
    },
    home12: {
        zh: "审核情报",
        en: "TI Verification"
    },
    home13: {
        zh: "API中心",
        en: "API"
    },
    home14: {
        zh: "模拟请求",
        en: "Mock A Request"
    },
    home15: {
        zh: "调用API",
        en: "API Manual"
    },
    home16: {
        zh: "常见问题",
        en: "Q&A"
    },
    // home17: {
    //     zh: "API接口说明",
    //     en: "API Manual"
    // },
    home17: {
        zh: "模拟请求",
        en: "API Manual"
    },

    home18: {
        zh: "下载SDK",
        en: "SDK Download"
    },
    home19: {
        zh: "天际友盟",
        en: "TianJi Partners"
    },
    home191: {
        zh: "威胁情报中心",
        en: "Threat Intelligence Center"
    },
    home20: {
        zh: "TI Inside",
        en: "TI Inside"
    },
    home201: {
        zh: "生态协同模式是专业威胁情报厂商与专业安全产品厂商合作的最佳实践",
        en: "ecological collaboration is the best practice for cooperation between professional threat and security intelligence providers."
    },
    home21: {
        zh: "搜索",
        en: "Search"
    },
    home22: {
        zh: "搜索类型",
        en: "Type"
    },
    home23: {
        zh: "历史搜索",
        en: "History"
    },
    home24: {
        zh: "推荐情报标签",
        en: "Tag Suggestions"
    },
    home25: {
        zh: "热点事件",
        en: "Hot"
    },
    home26: {
        zh: "信誉值",
        en: "Score"
    },
    home27: {
        zh: "搜索条件包含IP/域名/URL/Email/哈希值/CVE/CNVD/CNNVD编号/查询关键词等",
        en: "Please Input IP, Domain, URL, Email, Hash(MD5/SHA1/SHA256), CVE, CNVD or CNNVD ID"
    },
    home28: {
        zh: "活跃指示器(MRTI)",
        en: "MRTI"
    },
    home29: {
        zh: "域名",
        en: "DOMAIN"
    },
    home30: {
        zh: "高级情报(PRTI)",
        en: "PRTI"
    },
    home31: {
        zh: "黑客档案",
        en: "Hacker Profile"
    },
    home32: {
        zh: "重点情报 IMPORTANT TI",
        en: "IMPORTANT TI"
    },
    home321: {
        zh: "重点情报 IMPORTANT TI",
        en: "IMPORTANT TI"
    },
    home33: {
        zh: "时间",
        en: "Time"
    },
    home34: {
        zh: "指示器数量",
        en: "IOCs"
    },
    home35: {
        zh: "简要内容",
        en: "Overview"
    },
    home36: {
        zh: "查看更多",
        en: "More"
    },
    home37: {
        zh: "漏洞情报 VULNERABILITY",
        en: "VULNERABILITY"
    },
    home38: {
        zh: "披露时间",
        en: "Publicly"
    },
    home39: {
        zh: "更新时间",
        en: "Updated"
    },
    home40: {
        zh: "涉及厂商",
        en: "Provider Involved"
    },
    home41: {
        zh: "影响产品",
        en: "Product"
    },
    home42: {
        zh: "IOC 威胁指示器 INDICATOR OF COMPROMISE",
        en: "INDICATOR OF COMPROMISE"
    },
    home43: {
        zh: "免费数据示例下载",
        en: "Download Free Samples "
    },
    home44: {
        zh: "购买订阅",
        en: "Subscribe"
    },
    home45: {
        zh: "数据中仅限展示部分新增指示器，如需获取全部可通过购买订阅",
        en: "Only some of the new IOCs are displayed. For overall, please subscribe to the card"
    },
    home46: {
        zh: "评定时间",
        en: "Estimation Time"
    },
    home47: {
        zh: "指示器",
        en: "IOCs"
    },
    home48: {
        zh: "威胁类型",
        en: "Category"
    },
    home49: {
        zh: "标签",
        en: "Tag"
    },
    home50: {
        zh: "安全资讯 INFORMATION",
        en: "INFORMATION"
    },
    home51: {
        zh: "数据集合 DATA SET",
        en: "DATA SET"
    },
    home52: {
        zh: "已订阅",
        en: "Subscribed"
    },
    home53: {
        zh: "可购买",
        en: "Purchase"
    },
    home54: {
        zh: "可订阅",
        en: "Subscribe"
    },
    home55: {
        zh: "查看",
        en: "View"
    },
    home56: {
        zh: "订阅集合",
        en: "Subscribe"
    },
    home57: {
        zh: "申请集合",
        en: "Apply Now"
    },
    home58: {
        zh: "研究报告 RESEARCH REPORT",
        en: "RESEARCH REPORT"
    },
    home59: {
        zh: "查看报告",
        en: "View"
    },
    home60: {
        zh: "双子座实验室",
        en: "Gemini Laboratory"
    },
    home61: {
        zh: "快速入口",
        en: "Quick Entry"
    },
    home62: {
        zh: "威胁情报中心",
        en: "Threat Intelligence Center"
    },
    home63: {
        zh: "数字风险防护平台",
        en: "Digital Risk Protection Service"
    },
    home64: {
        zh: "威胁情报平台",
        en: "Threat Intelligence Platform"
    },
    home65: {
        zh: "联系我们",
        en: "Contact Us"
    },
    home66: {
        zh: "联系电话",
        en: "Phone"
    },
    home67: {
        zh: "客户服务",
        en: "Service"
    },
    home68: {
        zh: "市场合作",
        en: "Marketing"
    },
    home69: {
        zh: "合作伙伴",
        en: "Partnership"
    },
    home70: {
        zh: "天际友盟信息技术有限公司版权所有",
        en: "Tianji Partners Co., Ltd. All Rights Reserved"
    },
    home71: {
        zh: "京ICP备15055270号",
        en: "Beijing ICP 15055270"
    },
    home72: {
        zh: "未登录时仅支持查询3次，您的搜索次数已达上限，请先登录系统已获取更多查询次数。【去登录】",
        en: "Only 4 searches for visitors. Please log in for more. (Sing in)"
    },
    home73: {
        zh: "威胁情报",
        en: "Threat Intelligence"
    },
    home74: {
        zh: "数据加载中，请稍候",
        en: "Loading..."
    },
    home75: {
        zh: "服务开通",
        en: "Apply"
    },
    home76: {
        zh: "情报详情",
        en: "Details"
    },
    home77: {
        zh: "支持IP/域名/URL/Email/哈希值/漏洞编号/关键词等",
        en: "Please Input IP/Domain/URL/Email/Hash/Vulnerability ID/Keywords, etc."
    },



    // 个人中心
    user1: {
        zh: "基础信息",
        en: "Basic Info"
    },
    user2: {
        zh: "个人用户",
        en: "Visitor"
    },
    user3: {
        zh: "服务有效期至xx年xx月xx日",
        en: "The service is valid until DD/MM/YYY."
    },
    user311: {
        zh: "服务有效期至",
        en: "The service is valid until"
    },
    user4: {
        zh: "我的订阅",
        en: "My Subscription"
    },
    user5: {
        zh: "您还未开通相应服务",
        en: "No Access"
    },
    user6: {
        zh: "申请开通",
        en: "Apply"
    },
    user7: {
        zh: "溯源剩余次数",
        en: "Tracing Remaining Times"
    },
    user8: {
        zh: "页面查询",
        en: "Web Query"
    },
    user9: {
        zh: "API查询",
        en: "API Query"
    },
    user10: {
        zh: "未开通",
        en: "No Access"
    },
    user11: {
        zh: "无限次",
        en: "Unlimited Times"
    },
    user12: {
        zh: "永久有效",
        en: "Permanent"
    },
    user13: {
        zh: "次/日",
        en: "Times/Day"
    },
    user14: {
        zh: "次/月",
        en: "Times/Month"
    },
    user15: {
        zh: "次/年",
        en: "Times/Year"
    },
    user16: {
        zh: "注册时间",
        en: "Registration Time"
    },
    user17: {
        zh: "用户姓名",
        en: "Username"
    },
    user18: {
        zh: "已绑邮箱",
        en: "Email"
    },
    user19: {
        zh: "手机设置",
        en: "Mobile"
    },
    user20: {
        zh: "请输入手机号码",
        en: "Please enter cell"
    },
    user21: {
        zh: "请输入验证码",
        en: "Input  Verification code"
    },
    user22: {
        zh: "获取验证码",
        en: "Get verification code"
    },
    user23: {
        zh: "已发送",
        en: "Sent"
    },
    user24: {
        zh: "验证码错误",
        en: "Wrong code"
    },
    user25: {
        zh: "旧密码",
        en: "Old password"
    },
    user26: {
        zh: "请输入旧密码",
        en: "Please enter old password"
    },
    user27: {
        zh: "支持5-20个字符必须包含字母、数字、特殊字符(除空格外)的两种",
        en: "Support 5-20 characters, which must include at least two of the following: letters, numbers, and special characters (excluding spaces)."
    },
    user271: {
        zh: "支持5-20个字符",
        en: "Support 5-20 characters"
    },
    user272: {
        zh: "必须包含字母、数字、特殊字符(除空格外)的两种",
        en: "which must include at least two of the following: letters, numbers, and special characters (excluding spaces)."
    },
    user28: {
        zh: "新密码",
        en: "New password"
    },
    user29: {
        zh: "请输入新密码",
        en: "Please enter new password"
    },
    user30: {
        zh: "确认密码",
        en: "Confirm"
    },
    user31: {
        zh: "请输入图形验证码",
        en: "Captcha"
    },
    user32: {
        zh: "保存",
        en: "Save"
    },
    user33: {
        zh: "邮件通知",
        en: "E-mail notification"
    },
    user331: {
        zh: "集合数据更新通知",
        en: "Data Update Notification"
    },
    user34: {
        zh: "当您订阅的集合数据中，有report情报数据更新时，系统将通知您当天数据的更新情况。",
        en: "You will be informed when report TI in your subscribed sets has update."
    },
    user35: {
        zh: "周情报摘要",
        en: "Weekly TI Abstract"
    },
    user36: {
        zh: "一周重点情报内容摘要。",
        en: "Weekly Key TI Abstract"
    },
    user37: {
        zh: "站内信息",
        en: "In-site Information"
    },
    user38: {
        zh: "集合数据更新通知",
        en: "Data Update Notification"
    },
    user39: {
        zh: "当您订阅的集合数据中，有report情报数据更新时，系统将通知您当天数据的更新情况。",
        en: "You will be informed when report TI in your subscribed sets has update."
    },
    user40: {
        zh: "一周情报摘要",
        en: "Weekly TI Abstract"
    },
    user41: {
        zh: "一周重点情报内容摘要",
        en: "Weekly Key TI Abstract"
    },
    user42: {
        zh: "开启成功",
        en: "Successful"
    },
    user43: {
        zh: "您还未开通订阅服务，目前订阅服务仅向企业付费用户开放您可联系我们，解锁更多数据权限",
        en: "You have no access to the subsription, which is only available to paid users. Please  contact us for more info."
    },
    user431: {
        zh: "您还未开通订阅服务，目前订阅服务仅向企业付费用户开放您可联系我们，解锁更多数据权限",
        en: "You have no access to the subsription, which is only available to paid users. Please  contact us for more info."
    },
    user44: {
        zh: "申请开通",
        en: "Apply"
    },
    user45: {
        zh: "暂未开通",
        en: "No Access"
    },
    user46: {
        zh: "未开通",
        en: "No Access"
    },
    user47: {
        zh: "所属公司",
        en: "Company"
    },
    user48: {
        zh: "手机格式不正确",
        en: "The mobile number format is incorrect."
    },
    user49: {
        zh: "手机号不能为空",
        en: "Please fill in your phone number."
    },
    user50: {
        zh: "解绑",
        en: "Unbind"
    },
    user51: {
        zh: "绑定手机",
        en: "Bind"
    },
    user52: {
        zh: "登录密码",
        en: "Password"
    },
    user53: {
        zh: "用户姓名不能为空",
        en: "Please fill in your phone number."
    },
    user54: {
        zh: "用户名长度不能超过50个字符",
        en: "The username cannot exceed 50 characters in length."
    },
    user55: {
        zh: "请输入正确手机号",
        en: "Please enter a correct phone number."
    },
    user56: {
        zh: "请输入手机验证码",
        en: "Please fill in the verification code."
    },
    user57: {
        zh: "请输入正确验证码",
        en: "Wrong code"
    },
    user58: {
        zh: "密码支持5-20个字符",
        en: "The password supports 5 to 20 characters."
    },
    user59: {
        zh: "请输入确认密码",
        en: "Please fill in password."
    },
    user60: {
        zh: "确认密码与新密码不一致",
        en: "The confirmation password does not match the new password."
    },
    user601: {
        zh: "退出账号",
        en: "Sign out"
    },
    user61: {
        zh: "管理员",
        en: "Administrator"
    },
    user62: {
        zh: "用户中心",
        en: "User Center"
    },
    user63: {
        zh: "去开通",
        en: "Apply"
    },
    user64: {
        zh: "关闭成功",
        en: "Closed successfully"
    },
    user65: {
        zh: "服务开通",
        en: "Apply"
    },
    user66: {
        zh: "通知设置",
        en: "Notification Settings"
    },
    user67: {
        zh: "请输入邮箱",
        en: "Please fill in your email."
    },

    //消息中心
    message1: {
        zh: "消息中心",
        en: "Message Center"
    },
    message2: {
        zh: "未读",
        en: "Unread"
    },
    message3: {
        zh: "已读",
        en: "Read"
    },
    message4: {
        zh: "全部已读",
        en: "All read"
    },
    message5: {
        zh: "服务通知",
        en: "Service Notification"
    },
    message6: {
        zh: "系统通知",
        en: "System Notification"
    },
    message7: {
        zh: "数据更新通知",
        en: "New Data"
    },
    message8: {
        zh: "每周情报通知",
        en: "Weekly Keypoints"
    },
    message9: {
        zh: "业务服务通知",
        en: "Business"
    },
    message10: {
        zh: "订阅服务开通",
        en: "Subscription Activation"
    },
    message11: {
        zh: "服务到期提醒",
        en: "Service Expiration Reminder"
    },
    message12: {
        zh: "服务过期提醒",
        en: "Service Expired"
    },
    message13: {
        zh: "即将到期",
        en: "Expires Soon"
    },
    message14: {
        zh: "还有xx天",
        en: "Days Left"
    },
    message15: {
        zh: "展开",
        en: "Expand"
    },
    message16: {
        zh: "收起",
        en: "Pick Up"
    },
    message17: {
        zh: "订阅服务已生效",
        en: "Subscription Activated"
    },
    message18: {
        zh: "暂无消息",
        en: "No messages available."
    },
    message19: {
        zh: "服务到期提醒（还有",
        en: "Service Expiration Reminder"
    },


    //登录
    login1: {
        zh: "欢迎来到威胁情报中心",
        en: "Welcome To Threat Intelligence Center"
    },
    login2: {
        zh: "请输入手机号/用户名/邮箱",
        en: "Email/Username/Phone"
    },
    login3: {
        zh: "请输入图形验证码",
        en: "Captcha"
    },
    login4: {
        zh: "记住我",
        en: "Remember me"
    },

    login5: {
        zh: "登录",
        en: "Sign in"
    },
    login6: {
        zh: "忘记密码",
        en: "Forget Password"
    },
    login7: {
        zh: "还没有账号？注册",
        en: "No account? Free register"
    },
    login71: {
        zh: "注册",
        en: "Free register"
    },
    login72: {
        zh: "还没有账号？",
        en: "No account?"
    },
    login8: {
        zh: "操作成功",
        en: "Operation Successful"
    },
    login9: {
        zh: "操作失败",
        en: "Operation Failed"
    },
    login10: {
        zh: "登录失败，稍后重试",
        en: "Login Failed, Please Try Again Later."
    },
    login11: {
        zh: "登录成功",
        en: "Login Successful"
    },

    login12: {
        zh: "用户登录异常，请重新登录",
        en: "Abnormal login , please login again."
    },
    login13: {
        zh: "已登录,请刷新页面",
        en: "You have already logged in. Please refresh."
    },
    login14: {
        zh: "账号或密码错误",
        en: "Incorrect account or password."
    },
    login15: {
        zh: "账号异常",
        en: "Abnormal account."
    },
    login16: {
        zh: "账户信息错误",
        en: "Incorrect account information."
    },
    login17: {
        zh: "请输入账号",
        en: "Please fill in your account."
    },
    login18: {
        zh: "请输入登录密码",
        en: "Password"
    },
    login181: {
        zh: "请输入登录密码",
        en: "Please fill in password."
    },
    login19: {
        zh: "请输入验证码",
        en: "Please fill in the verification code."
    },
    login20: {
        zh: "验证码不正确",
        en: "Wrong code"
    },



    //搜索结果页
    searchResult1: {
        zh: "搜索条件包含CVE/CNVD/CNNVD编号/威胁组织/查询关键词等",
        en: "Search conditions include CVE, CNVD, CNNVD, threat actors or search keywords, etc."
    },
    searchResult2: {
        zh: "RedQueen为您找到XXX条情报",
        en: "RedQueen has found XXX pieces of intelligence for you."
    },
    searchResult21: {
        zh: "RedQueen为您找到",
        en: "RedQueen has found"
    },
    searchResult22: {
        zh: "条情报",
        en: "pieces of intelligence for you."
    },
    searchResult3: {
        zh: "发布来源",
        en: "Source"
    },
    searchResult4: {
        zh: "发布时间",
        en: "Time"
    },
    searchResult5: {
        zh: "暂无别称",
        en: "No alias"
    },
    searchResult6: {
        zh: "目标行业",
        en: "Target Industry"
    },
    searchResult7: {
        zh: "攻击水平",
        en: "Threat Level"
    },
    searchResult8: {
        zh: "指示器",
        en: "IOCs"
    },
    searchResult9: {
        zh: "历史活动",
        en: "History Activities"
    },
    searchResult10: {
        zh: "未知",
        en: "unknown"
    },
    searchResult11: {
        zh: "您当前权限支持查看部分内容，可联系我们 (+86)400-0810-700 或 service@tj-un.com 解锁更多情报。",
        en: "Only part of data is available. You can call  (+86)400-0810-700 or write an E-mail to service@tj-un.com for more access."
    },

    //全部高级情报页
    hiSearch1: {
        zh: "搜索条件包含CVE/CNVD/CNNVD编号/威胁组织/查询关键词等",
        en: "Search conditions include CVE, CNVD, CNNVD, threat actors or search keywords, etc."
    },
    hiSearch2: {
        zh: "RedQueen为您找到XXX条查询结果",
        en: "RedQueen has found XXX pieces of intelligence for you."
    },
    hiSearch3: {
        zh: "只看重点情报",
        en: "Only Show Key TI"
    },
    //情报详情页
    subDeail1: {
        zh: "基础信息",
        en: "Basic Information"
    },
    subDeail2: {
        zh: "红",
        en: "Red"
    },
    subDeail3: {
        zh: "黄",
        en: "Yellow"
    },
    subDeail4: {
        zh: "绿",
        en: "Green"
    },
    subDeail5: {
        zh: "关联情报",
        en: "Related TI"
    },
    subDeail6: {
        zh: "指示器",
        en: "IOCs"
    },
    subDeail7: {
        zh: "关联行业",
        en: "Relative Industry"
    },
    subDeail8: {
        zh: "概述",
        en: "Overview"
    },
    subDeail9: {
        zh: "参考信息",
        en: "Reference"
    },
    subDeail10: {
        zh: "攻击目标",
        en: "Attack Target"
    },
    subDeail11: {
        zh: "涉及厂商",
        en: "Provider Involved"
    },
    subDeail12: {
        zh: "受影响产品",
        en: "Product"
    },
    subDeail13: {
        zh: "目标CPE",
        en: "CPE"
    },
    subDeail14: {
        zh: "黑客组织或团体",
        en: "Threat Actors"
    },
    subDeail15: {
        zh: "攻击模式",
        en: "Attack Pattern"
    },
    subDeail16: {
        zh: "利用工具",
        en: "Tool Utilized"
    },
    subDeail17: {
        zh: "指示器",
        en: "Indicators Of Compromise"
    },
    subDeail18: {
        zh: "请输入关键字搜索",
        en: "Please enter search keywords."
    },
    subDeail19: {
        zh: "下载CSV",
        en: "Download CSV"
    },
    subDeail20: {
        zh: "权限暂未开通",
        en: "No access"
    },
    subDeail21: {
        zh: "类型",
        en: "Type"
    },
    subDeail22: {
        zh: "威胁类型",
        en: "Category"
    },
    subDeail23: {
        zh: "国家",
        en: "Country"
    },
    subDeail24: {
        zh: "应对措施",
        en: "Course of Action"
    },
    subDeail25: {
        zh: "防范",
        en: "Remedy"
    },
    subDeail26: {
        zh: "补救",
        en: "Response"
    },
    subDeail27: {
        zh: "您当前权限支持查看部分内容，可联系我们(+86) 400-0810-700或service@tj-un.com解锁更多情报。",
        en: "Only part of data is available. You can call  (+86)400-0810-700 or write an E-mail to service@tj-un.com for more access."
    },
    subDeail28: {
        zh: "更新日志",
        en: "Changelog"
    },
    //安全资讯
    security1: {
        zh: "安全资讯",
        en: "Security Information"
    },
    security2: {
        zh: "请输入关键字搜索",
        en: "Please enter keywords"
    },
    security21: {
        zh: "搜索",
        en: "Search"
    },
    security3: {
        zh: "RedQueen为您找到XXX条查询结果",
        en: "RedQueen has found XXX search results for you."
    },
    security4: {
        zh: "最新发布时间",
        en: "Lasted Publish Time"
    },
    security5: {
        zh: "资讯来源",
        en: "Source"
    },
    security6: {
        zh: "概述",
        en: "Overview"
    },
    security7: {
        zh: "参考信息",
        en: "Reference"
    },
    //全部研究报告
    researchReport1: {
        zh: "研究报告",
        en: "Research Report"
    },
    researchReport2: {
        zh: "查看报告",
        en: "View report"
    },
    //缺省页提示语
    tipEnandZh1: {
        zh: "您当前还未解锁此部分数据，可联系我们service@tj-un.com 解锁更多情报。",
        en: "No access to this data. You can contact us for more access."
    },
    tipEnandZh2: {
        zh: "申请开通",
        en: "Apply"
    },
    tipEnandZh3: {
        zh: "信息不存在或您没有访问权限",
        en: "No data or you have no access."
    },
    tipEnandZh4: {
        zh: "暂未开通",
        en: "No access."
    },
    tipEnandZh5: {
        zh: "您还未开通订阅服务，目前订阅服务仅向企业付费用户开放，您可联系我们，解锁更多数据权限。",
        en: "You have no access to subscription service which is only available to paid enterprise users. You can contact us for more access."
    },
    tipEnandZh6: {
        zh: "联系电话：（+86）400-0810-700",
        en: "Contact: (+86) 400-0810-7 00"
    },
    tipEnandZh7: {
        zh: "客户服务：service@tj-un.com",
        en: "Service: service@tj-un.com"
    },
    tipEnandZh8: {
        zh: "暂无消息",
        en: "No message."
    },
    tipEnandZh9: {
        zh: "您当前还未解锁此部分数据，可联系我们service@tj-un.com解锁更多情报。",
        en: "No access to this data. You can contact us via service@tj-un.com for more."
    },
    tipEnandZh10: {
        zh: "情报卡片购买通知",
        en: "TI cards purchase notification"
    },
    tipEnandZh11: {
        zh: "服务开通",
        en: "Service Activation"
    },
    verification1: {
        zh: "向右滑动进行安全验证",
        en: "Drag the slider to complete the puzzle"
    },
    verification2: {
        zh: "验证失败",
        en: "Verification failed"
    },
    verification3: {
        zh: "请重新尝试！",
        en: "Please try again"
    },
    verification4(time) {
        return {
            zh: "验证成功，耗时"+ time +"秒",
            en: "Verification successful, took "+ time +" seconds"
        }
    },
    verification5: {
        zh: "验证码被黑洞吸走了！",
        en: "The verification code has gone missing in action!"
    },


    //api
    apiCenter1: {
        zh: "API使用中心",
        en: "API"
    },
    apiCenter2: {
        zh: "下载接口文档",
        en: "Download API doc"
    },
    apiCenter3: {
        zh: "下载SDK",
        en: "Download SDK"
    },
    apiCenter4: {
        zh: "模拟请求",
        en: "Data Request"
    },
    apiCenter5: {
        zh: "调用API",
        en: "Introduction to API"
    },
    apiCenter6: {
        zh: "常见问题",
        en: "FAQ"
    },
    apiCenter7: {
        zh: "接口说明",
        en: "Description"
    },
    apiCenter8: {
        zh: "获取方式",
        en: "Acquisition Method"
    },
    apiCenter9: {
        zh: "接口地址",
        en: "Interface Address"
    },
    apiCenter10: {
        zh: "参数",
        en: "Parameters"
    },
    apiCenter11: {
        zh: "选择数据格式",
        en: "Select Data Format"
    },
    apiCenter12: {
        zh: "标准JSON",
        en: "JSON"
    },
    apiCenter13: {
        zh: "获取数据",
        en: "Try It"
    },
    apiCenter14: {
        zh: "返回结果",
        en: "RESPONSE"
    },
    apiCenter15: {
        zh: "复制",
        en: "copy"
    },
    apiCenter16: {
        zh: "导出",
        en: "export"
    },
    apiCenter17: {
        zh: "token不能为空，请填写相应参数",
        en: "Input required! Please enter."
    },
    apiCenter18: {
        zh: "query不能为空，请填写相应参数",
        en: "Input required! Please enter."
    },
    apiCenter19: {
        zh: "id不能为空，请填写相应参数",
        en: "Input required! Please enter."
    },
    apiCenter20: {
        zh: "type不能为空，请填写相应参数",
        en: "Input required! Please enter."
    },
    apiCenter21: {
        zh: "value不能为空，请填写相应参数",
        en: "Input required! Please enter."
    },
    apiCenter22: {
        zh: "struct不能为空，请填写相应参数",
        en: "Input required! Please enter."
    },
    apiCenter23: {
        zh: "溯源剩余次数",
        en: "Tracing Remaining Times"
    },
    apiCenter24: {
        zh: "次/日",
        en: "Times/Day"
    },
    apiCenter25: {
        zh: "次/月",
        en: "Times/Month"
    },
    apiCenter26: {
        zh: "次/年",
        en: "Times/Year"
    },
    apiCenter27: {
        zh: "您暂无API调用及模拟请求的使用权限，可邮件至support@tj-un.com申请或联系我们。",
        en: "Free users cannot use this feature. Please email support@tj-un.com or click on 'Contact Us'."
    },
    apiCenter28: {
        zh: "联系我们",
        en: "Contact Us"
    },
    apiCenter29: {
        zh: "申请开通",
        en: "Service Activation"
    },
    apiCenter30: {
        zh: "请填写如下联系信息，确认申请后，将会有相应市场人员与您沟通相应商务流程。如有疑问，可咨询4000810700或service@tj-un.com",
        en: "Pleasefillinthecontactinformationbelow,ourmarketerswillcommunicatewithyouassoonaspossible.Ifyouhaveanyquestions,pleaseconsult4000810700orservice@tj-un.com."
    },
    apiCenter31: {
        zh: "手机号",
        en: "Cell Phone Number"
    },
    apiCenter32: {
        zh: "请输入正确的手机号",
        en: "Please enter a valid cell phone number."
    },
    apiCenter33: {
        zh: "邮箱",
        en: "E-mail Address"
    },
    apiCenter34: {
        zh: "请输入正确的邮箱",
        en: "Please enter a valid E-mail address."
    },
    apiCenter35: {
        zh: "备注",
        en: "Notes"
    },
    apiCenter36: {
        zh: "功能说明",
        en: "Description"
    },
    apiCenter37: {
        zh: "请求方法",
        en: "Request Method"
    },
    apiCenter38: {
        zh: "请求参数说明",
        en: "Parameters"
    },
    apiCenter39: {
        zh: "使用方法",
        en: "Usage Method"
    },
    apiCenter40: {
        zh: "返回结果",
        en: "RESPONSE"
    },
    apiCenter41: {
        zh: "数据说明",
        en: "Data Description"
    },
    apiCenter42: {
        zh: "数据示例",
        en: "Example"
    },
    apiCenter43: {
        zh: "进行模拟请求",
        en: "Data Request"
    },
    apiCenter44: {
        zh: "是否必填",
        en: "Required"
    },
    apiCenter45: {
        zh: "说明",
        en: "Description"
    },
    apiCenter46: {
        zh: "字段",
        en: "Property Name"
    },
    apiCenter47: {
        zh: "类型",
        en: "Type"
    },
    apiCenter48: {
        zh: "描述",
        en: "Description"
    },
    apiCenter49: {
        zh: "数据类型",
        en: "Type"
    },
    apiCenter50: {
        zh: "请求地址",
        en: "Interface Address"
    },
    apiCenter51: {
        zh: "请求方式",
        en: "Request Method"
    },
    apiCenter52: {
        zh: "值",
        en: "value"
    },
    apiCenter53: {
        zh: "必填",
        en: "required"
    },
    apiCenter54: {
        zh: "选填",
        en: "optional"
    },
    apiCenter55: {
        zh: "增量",
        en: "Incremental Data"
    },
    apiCenter56: {
        zh: "全量",
        en: "Full Data"
    },
    apiCenter57: {
        zh: "您的查询次数已耗尽，请及时联系客服开通。",
        en: "Your inquiry quota has been exhausted. Please contact customer service promptly to activate it."
    },
    apiCenter58: {
        zh: "确定",
        en: "Close"
    },
    apiCenter59: {
        zh: "是",
        en: "Yes"
    },
    apiCenter60: {
        zh: "否",
        en: "No"
    },
    apiCenter61: {
        zh: "剩余溯源查询次数：",
        en: "Remaining traceability inquiry times:"
    },
    holeStatus2: {
        zh: "分析中",
        en: "Under Analysis"
    },
    holeStatusContent2: {
        zh: "此漏洞目前正在全力分析中，并非所有信息都已提供，仅支持查询参考。请稍后再次查看以获取完整的漏洞信息。",
        en: "This vulnerability is currently being fully analyzed, and not all information has been provided. It is only available for inquiry and reference at this time. Please check back later for complete vulnerability information."
    },
    holeStatus3: {
        zh: "待分析",
        en: "Pending Analysis"
    },
    holeStatusContent3: {
        zh: "此漏洞目前正等待详细分析，当前内容仅供查询参考。请稍后再次查看以获取完整的漏洞信息。",
        en: "This vulnerability is currently awaiting detailed analysis, and the current content is only for inquiry and reference. Please check back later for complete vulnerability information."
    }
    




















































}
export default languageList;