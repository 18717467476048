import React, { lazy, Suspense } from 'react'
/**
 * 此文件用于配置生成路由表的数据
 */
import { Navigate } from 'react-router-dom'
import Loading from '@/components/Loading'
const Home = lazy(() => import('@/pages/Home'));//首页
const Index = lazy(() => import('@/pages/Home/Main'));//首页内容
const Report = lazy(() => import('@/pages/Home/Report'));//报告列表
const Security = lazy(() => import('@/pages/Home/Security'));//资讯列表
const SecurityInfo = lazy(() => import('@/pages/Home/SecurityInfo'));//咨询详情
const Advanced = lazy(() => import('@/pages/Home/Advanced'));//高级情报
const Search = lazy(() => import('@/pages/Home/Search'));//搜索结果页
const IocPage = lazy(() => import('@/pages/Home/Ioc'));//Ioc搜索页
const InfoDetail = lazy(() => import('@/pages/Home/InfoDetail'));//情报详情
const Error403 = lazy(() => import('@/pages/Error/403'));
const Permission = lazy(() => import('@/pages/Error/Permission'));
const Error404 = lazy(() => import('@/pages/Error/404'));
const Demo = lazy(() => import('@/pages/Demo'))
const Login = lazy(() => import('@/pages/Login'))
const IntelligenceSub = lazy(() => import('@/pages/IntelligenceSub'))//情报订阅
const InfoMarket = lazy(() => import('@/pages/IntelligenceSub/InfoMarket'))//二级情报市场
const Card = lazy(() => import('@/pages/IntelligenceSub/InfoMarket/Card'))//三级情报卡片
const Aggregate = lazy(() => import('@/pages/IntelligenceSub/InfoMarket/Aggregate'))//三级数据集合
const InfoSub = lazy(() => import('@/pages/IntelligenceSub/InfoSub'))//二级情报订阅
const InfoSubList = lazy(() => import('@/pages/IntelligenceSub/InfoSub/List'))//三级情报订阅列表
const InfoSubColDetail = lazy(() => import('@/pages/IntelligenceSub/InfoSub/Detail/Collection'))//三级情报订阅集合详情
const InfoSubSenDetail = lazy(() => import('@/pages/IntelligenceSub/InfoSub/Detail/Sensor'))//三级情报订阅传感器详情
const InfoSubColCard = lazy(() => import('@/pages/IntelligenceSub/InfoSub/CreateCollection/Card'))//三级情报订阅按卡片创建集合
const InfoSubColTags = lazy(() => import('@/pages/IntelligenceSub/InfoSub/CreateCollection/Tags'))//三级情报订阅按标签创建集合

const IntelligenceHole = lazy(() => import('@/pages/IntelligenceHole'))//漏洞情报
// const List = lazy(() => import('@/pages/IntelligenceHole/List'))//二级列表
const HoleDetail = lazy(() => import('@/pages/IntelligenceHole/HoleDetail'))//二级漏洞详情
const DefaultList = lazy(() => import('@/pages/IntelligenceHole/DefaultList'))//三级默认列表
const SearchList = lazy(() => import('@/pages/IntelligenceHole/SearchList'))//三级搜索列表

const KnowledgeBase = lazy(() => import('@/pages/KnowledgeBase'))//威胁知识库
const Attack = lazy(() => import('@/pages/KnowledgeBase/Attack'))//二级攻击模式
const AttackNewDetail = lazy(() => import('@/pages/KnowledgeBase/AttackNewDetail'))//二级恶意软件详情
const Malware = lazy(() => import('@/pages/KnowledgeBase/Malware'))//二级恶意软件
const TreatOrg = lazy(() => import('@/pages/KnowledgeBase/TreatOrg'))//二级威胁组织
const TreatOrgDetail = lazy(() => import('@/pages/KnowledgeBase/TreatOrgDetail'))//二级威胁组织
const MalWareNewDetail = lazy(() => import('@/pages/KnowledgeBase/MalWareNewDetail'))//二级恶意软件详情
const Nolocked = lazy(() => import('@/pages/KnowledgeBase/Nolocked'))//二级恶意软件详情
const TreatOrImg = lazy(() => import('@/pages/KnowledgeBase/TreatOrImg'))//二级恶意软件详情


const UseTools = lazy(() => import('@/pages/KnowledgeBase/UseTools'))//二级利用工具
const ToolNewDetail = lazy(() => import('@/pages/KnowledgeBase/ToolNewDetail'))//二级利用工具详情
const InfoManagement = lazy(() => import('@/pages/InfoManagement'))//情报管理
const CreateInfo = lazy(() => import('@/pages/InfoManagement/CreateInfo'))//二级创建情报
const AuditInfo = lazy(() => import('@/pages/InfoManagement/AuditInfo'))//二级审核情报

// const ApiCenter = lazy(() => import('@/pages/ApiCenter'))//api中心
const ApiMock = lazy(() => import('@/pages/ApiMock'))//api中心
const MockRequest = lazy(() => import('@/pages/ApiMock/MockRequest'))//二级模拟请求
const Call = lazy(() => import('@/pages/ApiMock/Call'))//二级调用API
const Questions = lazy(() => import('@/pages/ApiMock/Questions'))//二级常见问题

const UserCenter = lazy(() => import('@/pages/UserCenter'))//用户中心
const Base = lazy(() => import('@/pages/UserCenter/Base'))//二级基础信息
const Set = lazy(() => import('@/pages/UserCenter/Set'))//二级通知设置
const Subscription = lazy(() => import('@/pages/UserCenter/Subscription'))//二级我的订阅
// const InfoCard = lazy(() => import('@/pages/UserCenter/Subscription/InfoCard'))//三级情报卡片
// const DataCollection = lazy(() => import('@/pages/UserCenter/Subscription/DataCollection'))//三级数据集合

// const ApiMock = lazy(() => import('@/pages/ApiMock'))//api
// const Simulaterequest = lazy(() => import('@/pages/ApiMock/Simulaterequest'))//api模拟请求


const Error = lazy(() => import('@/pages/Error'))//错误页面


function createSUS(dom) {
    return (
        <Suspense fallback={<Loading />}>
            {dom}
        </Suspense>
    )
}
const routerList_dev = [{
    path: "/demo",
    element: createSUS(<Demo />)
},{
    path: "/403",
    element: createSUS(<Error403 />)
},{
    path: "/permissionError",
    element: createSUS(<Permission />)
},{
    path: "/404",
    element: createSUS(<Error404 />)
},
{
    path: "/",
    element: <Navigate replace to="/home" />
},
{
    path: "/login",
    element: createSUS(<Login />)
},
{
    path: "/error",
    element: <Error />
}, {
    path: "/home",
    element: createSUS(<Home />),
    children:[
        {
            path:'index',
            element: createSUS(<Index/>)
        },
        {
            path:'report',
            element: createSUS(<Report/>)
        },
        {
            path:'security',
            element: createSUS(<Security/>)
        },
        {
            path:'security-info/:id',
            element: createSUS(<SecurityInfo/>)
        },
        {
            path:'advanced',
            element: createSUS(<Advanced/>)
        },
        {
            path:'ioc',
            element: createSUS(<IocPage/>)
        },
        {
            path:'ioc/:keyword',
            element: createSUS(<IocPage/>)
        },
        {
            path:'search',
            element: createSUS(<Search/>)
        },
        {
            path:'infoDetail/:id',
            element: createSUS(<InfoDetail/>)
        },{
            path: '/home',
            element: <Navigate replace to="index" />
        }
    ]
},
{
    path: "/subscription",
    element: createSUS(<IntelligenceSub />),
    children: [
        {
            path: 'market',
            element: createSUS(<InfoMarket />),
            children: [{
                path: 'card',
                element: createSUS(<Card />)
            }, {
                path: 'aggregate',
                element: createSUS(<Aggregate />)
            }, {
                path: '/subscription/market',
                element: <Navigate replace to="card" />
            }]
        },
        {
            path: "info-sub",
            element: createSUS(<InfoSub />),
            children: [
                {
                path: 'list',
                element: createSUS(<InfoSubList />),
            }, 
            {
                path: 'collection/:id',
                element: createSUS(<InfoSubColDetail />),
            }, {
                path: 'sensor/:id/:collectionId',
                element: createSUS(<InfoSubSenDetail />),
            }, {
                path: 'card',
                element: createSUS(<InfoSubColCard />),
            }, {
                path: 'card-edit/:id',
                element: createSUS(<InfoSubColCard />),
            }, {
                path: 'tags',
                element: createSUS(<InfoSubColTags />),
            }, {
                path: 'tags-edit/:id',
                element: createSUS(<InfoSubColTags />),
            }, {
                path: '/subscription/info-sub',
                element: <Navigate replace to="list" />
            }]
        },
        {
            path: "/subscription",
            element: <Navigate replace to="market" />
        }
    ]
},
{
    path: "/loophole",
    element: createSUS(<IntelligenceHole />),
    children: [
        //     {
        //     path: "list",
        //     element: createSUS(<List />),
        //     children: [{
        //         path: "default",
        //         element: createSUS(<DefaultList />)
        //     }, {
        //         path: "search",
        //         element: createSUS(<SearchList />)
        //     }, {
        //         path: '/loophole/list',
        //         element: <Navigate replace to="default" />
        //     }]
        // }, 
        {
            path: "search",
            element: createSUS(<SearchList />)
        }, {
            path: "default",
            element: createSUS(<DefaultList />)
        }, {
            path: "detail/:id",
            element: createSUS(<HoleDetail />)
        }, {
            path: "detail",
            element: <Navigate replace to="/loophole" />
        }, {
            path: '/loophole',
            element: <Navigate replace to="default" />
        }]
}, {
    path: "/knowledge",
    element: createSUS(<KnowledgeBase />),
    children: [{
        path: "attack",
        element: createSUS(<Attack />)
    }, {
        path: "marlware",
        element: createSUS(<Malware />)

    },{
        path: "treat-Img",
        // element: createSUS(<TreatOrg />),
        element: createSUS(<TreatOrImg />),
    }, {
        path: "treat-org",
        element: createSUS(<TreatOrg />),
        // element: createSUS(<TreatOrImg />),
    },
    {
        path: "treat-org/:id",
        element: createSUS(<TreatOrg/>),
        // element: createSUS(<TreatOrImg />),
    },
    {
        path: "treatorgdetail/:id",
        element: createSUS(<TreatOrgDetail/>)
    },
    {
        path: "malWareNewDetail/:id",
        element: createSUS(<MalWareNewDetail/>)
    },
    {
        path: "toolNewDetail/:id",
        element: createSUS(<ToolNewDetail/>)
        
    },
    {
        path: "attackNewDetail/:id",
        element: createSUS(<AttackNewDetail/>)
    },
     {
        path: "use-tools",
        element: createSUS(<UseTools />)
    }, 
    {
        path: "nolocked",
        element: createSUS(<Nolocked />)
    }, 
    {
        path: "/knowledge",
        element: <Navigate replace to="treat-org" />
    }]
}, {
    path: "/management",
    element: createSUS(<InfoManagement />),
    children: [{
        path: "create-info",
        element: createSUS(<CreateInfo />)
    }, {
        path: "audit-info",
        element: createSUS(<AuditInfo />)
    }, {
        path: "/management",
        element: <Navigate replace to="create-info" />
    }]
}, 
// {
//     path: "/api",
//     element: <Navigate replace to="/mock-request" />,//createSUS(<ApiCenter />),
//     children: [{
//         path: "mock-request",
//         element: <Navigate replace to="/mock-request" />//createSUS(<MockRequest />)
//     }, {
//         path: "call",
//         element: <Navigate replace to="/home" />//createSUS(<Call />)
//     }, {
//         path: "question",
//         element: <Navigate replace to="/home" />//createSUS(<Questions />)
//     }, {
//         path: "/api",
//         element: <Navigate replace to="/home" />//<Navigate replace to="mock-request" />
//     }]
// }, 
{
    path: "/api",
    element:  createSUS(<ApiMock />),//createSUS(<ApiMock />),
    children:[{
        path: "mock-request",
        element: createSUS(<MockRequest />)//createSUS(<MockRequest />)
    },{
        path: "mock-request/:id",
        element: createSUS(<MockRequest />)//createSUS(<Call />)
    }, {
        path: "call",
        element: createSUS(<Call />)//createSUS(<Call />)
    }, {
        path: "call/:id",
        element: createSUS(<Call />)//createSUS(<Call />)
    }, {
        path: "question",
        element: createSUS(<Questions />)//createSUS(<Questions />)
    }]
},
{
    path: "/center",
    element: createSUS(<UserCenter />),
    children: [{
        path: '/center',
        element: <Navigate replace to="base" />
    }, {
        path: 'set',
        element: createSUS(<Set />)
    }, {
        path: 'base',
        element: createSUS(<Base />)
    }, {
        path: 'subscription',
        element: createSUS(<Subscription />),
        //     children: [{
        //         path: 'card',
        //         element: createSUS(<InfoCard />),
        //     }, {
        //         path: 'data',
        //         element: createSUS(<DataCollection />),
        //     }
        //     , {
        //         path: '/center/subscription',
        //         element: <Navigate replace to="card" />
        //     }
        // ]
    }]
}, {
    path: '*',
    element: <Navigate replace to="/" />,//路径错误重定向
}
]
const routerList = {
    demo: {
        path: "/demo",
        element: createSUS(<Demo />)
    },
    default: {
        path: "/",
        element: <Navigate replace to="/home" />
    },
    login: {
        path: "/login",
        element: createSUS(<Login />)
    }, home: {
        path: "/home",
        element: createSUS(<Home />),
        children:[
            {
                path:'index',
                element: createSUS(<Index/>),
            },
            {
                path:'report',
                element: createSUS(<Report/>)
            },
            {
                path:'security',
                element: createSUS(<Security/>)
            },
            {
                path:'security-info/:id',
                element: createSUS(<SecurityInfo/>)
            },
            {
                path:'advanced',
                element: createSUS(<Advanced/>)
            },
            {
                path:'ioc',
                element: createSUS(<IocPage/>)
            },
            {
                path:'ioc/:keyword',
                element: createSUS(<IocPage/>)
            },
            {
                path:'search',
                element: createSUS(<Search/>)
            },
            {
                path:'infoDetail/:id',
                element: createSUS(<InfoDetail/>)
            },{
                path: '/home',
                element: <Navigate replace to="index" />
            }
        ]
    },
    subscription: {
        path: "/subscription",
        element: createSUS(<IntelligenceSub />),
        children: {
            market: {
                path: 'market',
                element: createSUS(<InfoMarket />),
                children: [{
                    path: 'card',
                    element: createSUS(<Card />)
                }, {
                    path: 'aggregate',
                    element: createSUS(<Aggregate />)
                }, {
                    path: '/subscription/market',
                    element: <Navigate replace to="card" />
                }]
            },
            "info-sub": {
                path: "info-sub",
                element: createSUS(<InfoSub />),
                children: [
                    {
                    path: 'list',
                    element: createSUS(<InfoSubList />),
                }, 
                {
                    path: 'collection/:id',
                    element: createSUS(<InfoSubColDetail />),
                }, {
                    path: 'sensor/:id/:collectionId',
                    element: createSUS(<InfoSubSenDetail />),
                }, {
                    path: 'card',
                    element: createSUS(<InfoSubColCard />),
                }, {
                    path: 'card-edit/:id',
                    element: createSUS(<InfoSubColCard />),
                }, {
                    path: 'tags',
                    element: createSUS(<InfoSubColTags />),
                }, {
                    path: 'tags-edit/:id',
                    element: createSUS(<InfoSubColTags />),
                }, {
                    path: '/subscription/info-sub',
                    element: <Navigate replace to="list" />
                }]
            },
            default: {
                path: "/subscription",
                element: <Navigate replace to="market" />
            }
        }

    },
    loophole: {
        path: "/loophole",
        element: createSUS(<IntelligenceHole />),
        children: [
            //     {
            //     path: "list",
            //     element: createSUS(<List />),
            //     children: [{
            //         path: "default",
            //         element: createSUS(<DefaultList />)
            //     }, {
            //         path: "search",
            //         element: createSUS(<SearchList />)
            //     }, {
            //         path: '/loophole/list',
            //         element: <Navigate replace to="default" />
            //     }]
            // }, 
            {
                path: "search",
                element: createSUS(<SearchList />)
            }, {
                path: "default",
                element: createSUS(<DefaultList />)
            }, {
                path: "detail/:id",
                element: createSUS(<HoleDetail />)
            }, {
                path: "detail",
                element: <Navigate replace to="/loophole" />
            }, {
                path: '/loophole',
                element: <Navigate replace to="default" />
            }]
    },
    knowledge: {
        path: "/knowledge",
        element: createSUS(<KnowledgeBase />),
        children: [
            {
                path: "attack",
                element: createSUS(<Attack />)
            },{
                path: "marlware",
                element: createSUS(<Malware />)
            },
            {
                path: "treat-Img",
                // element: createSUS(<TreatOrg />),
                element: createSUS(<TreatOrImg />),
            },
            {
                path: "treat-org",
                element: createSUS(<TreatOrg/>),
                // element: createSUS(<TreatOrImg />),
        
            },
            {
                path: "treat-org/:id",
                element: createSUS(<TreatOrg/>),
                // element: createSUS(<TreatOrImg />),
        
            },
            {
                path: "treatorgdetail/:id",
                element: createSUS(<TreatOrgDetail/>)
            },
            {
                path: "malWareNewDetail/:id",
                element: createSUS(<MalWareNewDetail/>)
            },
            {
                path: "toolNewDetail/:id",
                element: createSUS(<ToolNewDetail/>)
            },
            {
                path: "attackNewDetail/:id",
                element: createSUS(<AttackNewDetail/>)
            },
            {
                path: "use-tools",
                element: createSUS(<UseTools />)
            },
            {
                path: "nolocked",
                element: createSUS(<Nolocked />)
            }, 
            
            // {
            //     path: "default",
            //     element: createSUS(<TreatOrg />)
            // },
            {
                path: "/knowledge",
                element: <Navigate replace to="treat-org" />
            }]
        
    }, management: {
        path: "/management",
        element: createSUS(<InfoManagement />),
        children: {
            "create-info": {
                path: "create-info",
                element: createSUS(<CreateInfo />)
            }, "audit-info": {
                path: "audit-info",
                element: createSUS(<AuditInfo />)
            }, default: {
                path: "/management",
                element: <Navigate replace to="create-info" />
            }
        }
    }, 
    // api: {
    //     path: "/api",
    //     element: <Navigate replace to="/mock-request" />,//createSUS(<ApiCenter />),
    //     children: {
    //         "mock-request": {
    //             path: "mock-request",
    //             element: <Navigate replace to="/mock-request" />//createSUS(<MockRequest />)
    //         }, call: {
    //             path: "call",
    //             element: <Navigate replace to="/home" />//createSUS(<Call />)
    //         }, question: {
    //             path: "question",
    //             element: <Navigate replace to="/home" />//createSUS(<Questions />)
    //         }, default: {
    //             path: "/api",
    //             element: <Navigate replace to="/home" />//<Navigate replace to="mock-request" />
    //         }
    //     }
    // }
    api: {
        path: "/api",
        element: createSUS(<ApiMock />),//createSUS(<ApiCenter />),
        children:[{
                path: "mock-request",
                element: createSUS(<MockRequest />),//createSUS(<MockRequest />)
            }, {
                path: "mock-request/:id",
                element: createSUS(<MockRequest />)//createSUS(<Call />)
            },{
                path: "call",
                element: createSUS(<Call />)//createSUS(<Call />)
            },  {
                path: "call/:id",
                element: createSUS(<Call />)//createSUS(<Call />)
            }, {
                path: "question",
                element: createSUS(<Questions />)//createSUS(<Questions />)
            }
        ]
    }
}
export default function routes(menu) {
    if (menu) {
        let routerArr = [{
            path: '*',
            element: <Navigate replace to="/" />,//路径错误重定向
        }, {
            path: "/demo",
            element: createSUS(<Demo />)
        },{
            path: "/403",
            element: createSUS(<Error403 />)
        },{
            path: "/permissionError",
            element: createSUS(<Permission />)
        },{
            path: "/404",
            element: createSUS(<Error404/>)
        },
        {
            path: "/",
            element: <Navigate replace to="/home" />
        },
        {
            path: "/error",
            element: <Error />
        },
        {
            path: "/login",
            element: createSUS(<Login />)
        }, {
            path: "/center",
            element: createSUS(<UserCenter />),
            children: [{
                path: '/center',
                element: <Navigate replace to="base" />
            }, {
                path: 'set',
                element: createSUS(<Set />)
            }, {
                path: 'base',
                element: createSUS(<Base />)
            }, {
                path: 'subscription',
                element: createSUS(<Subscription />),
                //     children: [{
                //         path: 'card',
                //         element: createSUS(<InfoCard />),
                //     }, {
                //         path: 'data',
                //         element: createSUS(<DataCollection />),
                //     }
                //     , {
                //         path: '/center/subscription',
                //         element: <Navigate replace to="card" />
                //     }
                // ]
            }]
        }
        ];
        menu.map((item) => {
            const { path, element, children ,title} = routerList[item.type];
            let result = null;
            if (item.type == "loophole" ||item.type == "knowledge" ||item.type == "home" || item.type == "api") {
                result = { path, element, children};
            } else {
                result = { path, element};
            }
            if (item.menu&&item.type !== "knowledge"&&item.type != "api") {
                result.children = [];
                item.menu.map((data) => {
                    result.children.push(children[data.type])
                })
                result.children.push(children.default)
            }
            routerArr.push(result)
        })
  
        return routerArr;
    } else {
        return routerList_dev
    }
}